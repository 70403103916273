import "../polyfill.js";

import {} from "jquery-ujs";
import "bootstrap/js/dist/tab";

// Timezone setting
import moment from "moment-timezone";

export const setTimezone = (timezone: string) => {
  moment.tz.setDefault(timezone);
};

import React from "react";
import ReactDOM from "react-dom";

// Core components that can render the page
import App from "../components/App";
import Page from "../components/Page";
import ApiExplorer from "../components/ApiExplorer";

// Top-level React components
import AccountSettings from "../components/AccountSettings";
import DocsPage from "../components/DocsPage";
import GoogleSignIn from "../components/GoogleSignIn";
import Login from "../components/Login";
import Signup from "../components/Signup";

const topLevelComponents = {
  AccountSettings,
  DocsPage,
  GoogleSignIn,
  Login,
  Signup,
};

export const renderTopLevelComponent = (
  graphqlUri: string,
  id: string,
  name: string,
  props: { [key: string]: any },
) => {
  const elem = document.getElementById(id);
  if (elem) {
    ReactDOM.render(
      <App graphqlUri={graphqlUri}>
        {React.createElement(topLevelComponents[name], props)}
      </App>,
      elem,
    );
  }
};

export const renderPageComponent = (
  graphqlUri: string,
  id: string,
  props: { [key: string]: any },
) => {
  const elem = document.getElementById(id);
  if (elem) {
    ReactDOM.render(
      <App graphqlUri={graphqlUri}>
        <Page {...props} />
      </App>,
      elem,
    );
  }
};

export const renderApiExplorer = (
  graphqlUri: string,
  enterpriseEdition: boolean,
  id: string,
) => {
  const elem = document.getElementById(id);
  if (elem) {
    ReactDOM.render(
      <ApiExplorer
        graphqlUri={graphqlUri}
        enterpriseEdition={enterpriseEdition}
      />,
      elem,
    );
  }
};
