import React from "react";

import { formatNumber, formatBytes, formatMs } from "utils/format";

import { Plan } from "types/explain";

import styles from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  plan: Plan;
  runtime: number;
  ioBlocks: number;
  blockSize: number;
  ioMs: number;
};

const ExplainStatsAndOptions: React.FunctionComponent<Props> = ({
  plan,
  runtime,
  ioBlocks,
  blockSize,
  ioMs,
}) => {
  const firstPlan = plan[0];
  const topNode = firstPlan.Plan;
  const totCost =
    "Total Cost" in topNode ? formatNumber(topNode["Total Cost"]) : "N/A";
  const ioMsStr =
    ioMs != null
      ? formatMs(ioMs)
      : "I/O Read Time" in topNode
      ? formatMs(topNode["I/O Read Time"])
      : "-";
  const ioBlocksStr =
    ioBlocks != null
      ? formatBytes(ioBlocks * blockSize)
      : "Shared Read Blocks" in topNode
      ? formatBytes(topNode["Shared Read Blocks"] * blockSize)
      : "-";

  const options = {
    Analyze: "Actual Rows" in topNode,
    Verbose: "Output" in topNode,
    Costs: "Total Cost" in topNode,
    Buffers: "Shared Hit Blocks" in topNode,
    Timing: "Actual Total Time" in topNode,
    Summary: "Planning Time" in firstPlan,
  };
  return (
    <div>
      <dl className={styles.explainStats}>
        <div>
          <dd>{formatMs(runtime)}</dd>
          <dt>Runtime</dt>
        </div>
        <div>
          <dd>{ioMsStr}</dd>
          <dt>I/O Read Time</dt>
        </div>
        <div>
          <dd>{ioBlocksStr}</dd>
          <dt>Read From Disk</dt>
        </div>
        <div>
          <dd>{totCost}</dd>
          <dt>Total Est. Cost</dt>
        </div>
      </dl>
      <dl className={styles.explainOptions}>
        {Object.entries(options).map(([option, on]) => {
          return (
            <React.Fragment key={option}>
              <div className={styles.explainOptionItem}>
                <dd>
                  <FontAwesomeIcon icon={on ? faCheck : faTimes} />
                </dd>
                <dt>{option}</dt>
              </div>
            </React.Fragment>
          );
        })}
      </dl>
    </div>
  );
};

export default ExplainStatsAndOptions;
