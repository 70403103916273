import React from "react";

import { useQuery } from "@apollo/client";
import classNames from "classnames";
import moment from "moment";
import pluralize from "pluralize";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faDownload,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";

import Badge from "components/Badge";
import Loading from "components/Loading";
import { useRoutes } from "utils/routes";
import { useParams } from "react-router-dom";

import QUERY from "./Query.graphql";

import {
  PlanComparison as PlanComparisonType,
  PlanComparisonVariables,
  PlanComparison_getOrganizationBilling_planChoices as PlanInfo,
} from "./types/PlanComparison";
import { formatNumber } from "utils/format";

type Props = {
  isTrial: boolean;
  expiredTrial: boolean;
  trialEndsAt: number | null;
};

const PlanComparison: React.FunctionComponent<Props> = ({
  isTrial,
  expiredTrial,
  trialEndsAt,
}) => {
  const { slug: organizationSlug } = useParams();

  const { loading, error, data } = useQuery<
    PlanComparisonType,
    PlanComparisonVariables
  >(QUERY, {
    variables: {
      organizationSlug,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return (
    <PlanComparisonContent
      isTrial={isTrial}
      trialEndsAt={trialEndsAt != null ? moment.unix(trialEndsAt) : null}
      expiredTrial={expiredTrial}
      plans={data.getOrganizationBilling.planChoices}
    />
  );
};

const PlanComparisonContent: React.FunctionComponent<{
  isTrial: boolean;
  trialEndsAt: moment.Moment | null;
  expiredTrial: boolean;
  plans: PlanInfo[];
}> = ({ isTrial, trialEndsAt, expiredTrial, plans }) => {
  // Hide lite and trial: treat these as a trial of Scale
  const allPlansToCompare = plans.filter(
    (p) => p.id !== "trial" && p.id !== "lite",
  );
  // We only want to ever show three columns in the comparison to keep things
  // simpler, so if a user is on a legacy plan, omit the Enterprise plan
  const omitEnterprise = allPlansToCompare.some((p) => p.isHidden);
  const plansToCompare = allPlansToCompare.filter(
    (p) => !omitEnterprise || p.id !== "enterprise_cloud",
  );

  return (
    <table className="pricing-feature-matrix">
      <thead>
        <tr>
          <th className="border-r border-[#E6E6E9]">
            <h1>Compare our Plans</h1>
            <p className="font-normal pl-5 max-w-xs">
              You can download our features and plans comparison as PDF
              <span className="block mt-2">
                <a
                  target="_blank"
                  href="https://resources.pganalyze.com/pganalyze_postgres_monitoring_pricing_and_features.pdf"
                >
                  <FontAwesomeIcon icon={faDownload} /> Download PDF
                </a>
              </span>
            </p>
          </th>
          {plansToCompare.map((p) => {
            // note that 'expiredTrial' includes the lite plan here
            const isCurrent =
              isTrial || expiredTrial ? p.id === "scale_v4" : p.isCurrent;
            return (
              <PricingPlanHeader
                key={p.id}
                trialEndsAt={trialEndsAt}
                expiredTrial={expiredTrial}
                planId={p.id}
                planName={p.name}
                cost={p.formattedMonthlyPrice}
                isCurrent={isCurrent}
                isDowngrade={p.isDowngrade}
                canAddExtraServers={p.formattedServerOveragePrice != null}
                isEnterprise={p.id === "enterprise_cloud"}
              />
            );
          })}
        </tr>
      </thead>
      <PricingTableBody pricingInfo={deriveComparePlanInfo(plansToCompare)} />
    </table>
  );
};

const PricingPlanHeader: React.FunctionComponent<{
  planId: string;
  planName: string;
  canAddExtraServers: boolean;
  cost: string;
  isCurrent: boolean;
  isDowngrade: boolean;
  isEnterprise: boolean;
  trialEndsAt: moment.Moment;
  expiredTrial: boolean;
}> = ({
  planId,
  planName,
  canAddExtraServers,
  cost,
  isCurrent,
  isDowngrade,
  isEnterprise,
  trialEndsAt,
  expiredTrial,
}) => {
  const { slug: organizationSlug } = useParams();
  const { organizationSubscriptionActivate } = useRoutes();

  const daysRemaining =
    trialEndsAt == null
      ? 0
      : (trialEndsAt.valueOf() - Date.now()) / (24 * 60 * 60 * 1000);
  const timeRemainingBlurb =
    daysRemaining > 1
      ? pluralize("day", Math.floor(daysRemaining), true) + " left in trial"
      : daysRemaining > 0
      ? "Less than 24h left in trial"
      : "Trial Expired";

  const badgeText = !isCurrent
    ? null
    : trialEndsAt || expiredTrial
    ? timeRemainingBlurb
    : "Current plan";

  const changePlanAction =
    trialEndsAt != null
      ? `Choose ${planName}`
      : isDowngrade
      ? `Downgrade to ${planName}`
      : `Upgrade to ${planName}`;

  return (
    <th className="border-r border-[#E6E6E9] text-center align-top">
      <span
        className={classNames(
          "block font-semibold text-base leading-[22px] tracking-normal mt-[22px] uppercase whitespace-nowrap",
        )}
      >
        {planName}
      </span>
      {isEnterprise ? (
        <p className="text-xs font-normal leading-4 mt-4 mb-2 pt-[10px] h-[42px]">
          Learn more about
          <br />
          our Enterprise Solution
        </p>
      ) : (
        <p className="text-xs font-normal leading-3 mt-4 mb-2">
          <span className="block text-center font-bold text-[22px] leading-[30px] tracking-normal">
            {cost}
          </span>
          per month
        </p>
      )}
      <div
        className={classNames(
          "my-2 font-medium",
          badgeText === null && "invisible",
        )}
      >
        <Badge jumbo>{badgeText ?? <>&nbsp;</>}</Badge>
      </div>
      {isEnterprise ? (
        <PricingHeaderLink href="https://pganalyze.com/contact">
          Contact Sales
        </PricingHeaderLink>
      ) : isCurrent && trialEndsAt == null && !expiredTrial ? (
        <PricingHeaderLink
          className={!canAddExtraServers && "invisible"}
          href={organizationSubscriptionActivate(organizationSlug, planId)}
        >
          Adjust Server Limit
        </PricingHeaderLink>
      ) : (
        <PricingHeaderLink
          href={organizationSubscriptionActivate(organizationSlug, planId)}
        >
          {changePlanAction}
        </PricingHeaderLink>
      )}
    </th>
  );
};

const PricingHeaderLink: React.FunctionComponent<
  React.HTMLProps<HTMLAnchorElement>
> = ({ className, ...rest }) => {
  // These links look mostly like our Bootstrap btn (see bootstrap_legacy.scss),
  // but we can't style them as buttons and override with Tailwind, because both
  // the Tailwind utility class and the Boostrap button class have the same
  // specificity, so the order that the CSS definitions are loaded in determines
  // precedence, and we can't move Tailwind up in index.scss because it would
  // start taking precedence over Bootstrap styles everywhere (which we are not
  // ready for). For now, reimplement button styles in Tailwind but style to
  // match our public pricing comparison page.
  return (
    <a
      className={classNames(
        `inline-block mb-0 font-normal text-center align-middle whitespace-nowrap
  touch-manipulation cursor-pointer px-5 py-2 text-sm rounded select-none
  text-[#2ea9a9] border-[#2ea9a9] border-2 hover:bg-[#2ea9a9] hover:text-white`,
        className,
      )}
      {...rest}
    />
  );
};

const PricingTableBody: React.FunctionComponent<{
  pricingInfo: AllPricingInfoType;
}> = ({ pricingInfo }) => {
  return (
    <tbody>
      {pricingInfo.featureSupport.map((grouping, i) => {
        return (
          <React.Fragment key={i}>
            <tr data-category>
              {grouping.items[0].map((_part, i) => {
                if (i === 0) {
                  return (
                    <td key={i}>
                      <h2>{grouping.category}</h2>
                    </td>
                  );
                } else {
                  return <td key={i} />;
                }
              })}
            </tr>
            {grouping.items.map((item, j) => {
              const [feature, ...onPlans] = item;
              return (
                <tr key={j}>
                  <td>{feature}</td>
                  {onPlans.map((planSupport, k) => {
                    const currentPlan = pricingInfo.plans[k];
                    return (
                      <FeatureSupportCell
                        key={k}
                        value={planSupport}
                        info={currentPlan}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

const FeatureSupportCell: React.FunctionComponent<{
  value: React.ReactNode | ((info: PlanInfo) => React.ReactNode);
  info: PlanInfo;
}> = ({ value, info }) => {
  return (
    <td>
      {typeof value === "function" ? (
        value(info)
      ) : value === true ? (
        <span role="img" aria-label="Yes">
          <FontAwesomeIcon icon={faCheck} />
        </span>
      ) : value === false ? (
        <span role="img" aria-label="No">
          <FontAwesomeIcon icon={faTimes} />
        </span>
      ) : (
        value
      )}
    </td>
  );
};

type FeatureDescription =
  | React.ReactNode
  | ((info: PlanInfo) => React.ReactNode);

type PlanFeatureType = readonly [
  feature: React.ReactNode,
  prodStatus: FeatureDescription,
  scaleStatus: FeatureDescription,
  enterpriseStatus: FeatureDescription,
];

type PricingInfoType = readonly {
  readonly category: string;
  readonly items: readonly PlanFeatureType[];
}[];

// includes info about a legacy plan, if that is what the user is using
type AllPlanFeatureType = readonly [
  feature: React.ReactNode,
  ...planStatuses: FeatureDescription[],
];

type AllPricingInfoType = {
  plans: PlanInfo[];
  featureSupport: readonly {
    readonly category: string;
    readonly items: readonly AllPlanFeatureType[];
  }[];
};

const basePricingInfo: PricingInfoType = [
  {
    category: "Pricing",
    items: [
      [
        "Included Servers",
        "1 server",
        (info: PlanInfo) => <>{info.serversIncluded}+ billable servers</>,
        "Contact Sales",
      ],
      [
        <>
          Price For Additional Billable Servers{" "}
          <a
            href="https://pganalyze.com/docs/accounts/billing"
            className="pricing-docs-link"
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              title="What counts as a billable server?"
            />
          </a>
        </>,
        "n/a",
        (info: PlanInfo) => (
          <>
            <strong>{info.formattedServerOveragePrice}</strong> / month
            <br />
            per billable server
          </>
        ),
        "Contact Sales",
      ],
      [
        "Price For Primaries / Writer Instances",
        "n/a",
        <>
          1 primary =<br />
          <strong>1.0</strong> billable servers
        </>,
        <>
          1 primary =<br />
          <strong>1.0</strong> billable servers
        </>,
      ],
      [
        "Price For Replicas / Reader Instances",
        "n/a",
        (info: PlanInfo) => (
          <>
            1 replica =<br />
            <strong>
              {formatNumber(info.replicaBillingMultiplier, 1)}
            </strong>{" "}
            billable servers
          </>
        ),
        (info: PlanInfo) => (
          <>
            1 replica =<br />
            <strong>
              {formatNumber(info.replicaBillingMultiplier, 1)}
            </strong>{" "}
            billable servers
          </>
        ),
      ],
      ["Payment Term", "Monthly or Annual", "Monthly or Annual", "Annual"],
    ],
  },
  {
    category: "Limits",
    items: [
      [
        "Support For x Database Servers",
        "1 server",
        (info: PlanInfo) => <>{info.serversIncluded}+ servers</>,
        "10+ servers",
      ],
      [
        "Support For x Users",
        "Unlimited users",
        "Unlimited users",
        "Unlimited users",
      ],
      ["Historic Statistics Retention Time", "14 days", "30 days", "30 days"],
      [
        "Table Limit",
        "5,000 tables per server",
        "5,000 tables per server",
        "25,000+ tables per server",
      ],
      [
        "Included Volume for Log Insights",
        "n/a",
        "1 GB / day per server",
        <>
          2 GB / day per server
          <br />
          for Enterprise Cloud
        </>,
      ],
    ],
  },
  {
    category: "Database Optimization",
    items: [
      ["Config Tuning Recommendations", true, true, true],
      ["pganalyze Index Advisor", true, true, true],
      ["pganalyze VACUUM Advisor", false, true, true],
      ["Buffer Cache Hit Ratio for each query", true, true, true],
      ["Identify Poorly Performing Indexes", true, true, true],
      ["Automated Health Checks", true, true, true],
    ],
  },
  {
    category: "Security & Collaboration",
    items: [
      ["Enable DBAs And Developers To Collaborate", true, true, true],
      [
        <>
          Option To Run On-Premise / Self-Hosted with{" "}
          <a
            target="_blank"
            rel="noopener"
            href="https://pganalyze.com/enterprise-postgres-monitoring"
          >
            pganalyze Enterprise Server
          </a>
        </>,
        false,
        false,
        true,
      ],
      ["Careful PII handling", false, true, true],
      ["Query Normalization", true, true, true],
      ["Encrypted Log Monitoring", false, true, true],
      ["Restrict Access To Specific Databases and Servers", true, true, true],
      ["Role-Based Access Control", true, true, true],
      ["Single Sign-On (SSO): SAML", false, true, true],
      ["Single Sign-On (SSO): Active Directory / LDAP", false, false, true],
      [
        "Share Production Statistics Without Sharing Database Access",
        true,
        true,
        true,
      ],
      [
        "Store Statistics In Compliance With Local Policies",
        false,
        false,
        true,
      ],
      ["Streamlined Issue Handling", true, true, true],
    ],
  },
  {
    category: "Performance Analysis",
    items: [
      ["Detailed Query Performance Insights", true, true, true],
      ["Discover Slow Queries", true, true, true],
      ["Root Cause Analysis For Critical Issues", true, true, true],
      ["Find Missing Indexes", true, true, true],
      [
        "Automatic Collection Of Explain Plans With auto_explain",
        false,
        true,
        true,
      ],
      ["EXPLAIN Visualization", false, true, true],
      ["EXPLAIN Insights", false, true, true],
      ["Database Growth Monitoring", true, true, true],
      ["Real-Time Metrics", false, true, true],
      ["Unified Platform for Log and Query Monitoring", false, true, true],
      ["I/O And CPU Utilization Insights For Each Query", true, true, true],
      ["Specific Query Samples", false, true, true],
      ["Capture Bind Parameter Values", false, true, true],
      ["Bloat Estimates for Each Table", false, true, true],
      ["Helpful Dashboards", true, true, true],
      ["Drilldown Into Specific Queries", true, true, true],
      ["Detailed Statistics On A Per-Query Basis", true, true, true],
      ["Identify Which User A Slow Query Belongs To", true, true, true],
    ],
  },
  {
    category: "Incident Response",
    items: [
      ["Replication Monitoring", true, true, true],
      ["Connection Tracing", false, true, true],
      ["Wait Event Monitoring", false, true, true],
      ["pganalyze Log Insights", false, true, true],
      ["100+ Preconfigured Log Event Filters", false, true, true],
      ["Pre-Configured Email Alerts", true, true, true],
      ["CPU Statistics", true, true, true],
      ["Disk Metrics", true, true, true],
      ["Customizable Alerts and Notifications", true, true, true],
      [
        "Identify Urgent Problems Across 100+ Database Servers",
        false,
        true,
        true,
      ],
    ],
  },
] as const;

function deriveComparePlanInfo(plans: PlanInfo[]): AllPricingInfoType {
  return {
    plans,
    featureSupport: basePricingInfo.map((group) => {
      return {
        category: group.category,
        items: group.items.map((item) => {
          const [feature, onProd, onScale, onEnterprise] = item;
          return [
            feature,
            ...plans.map((p) => {
              if (p.id === "production_v3") {
                return onProd;
              } else if (p.id === "scale_v3" || p.id === "scale_v4") {
                // Scale v3 and v4 are identical from a feature perspective (for now)
                return onScale;
              } else if (p.id === "enterprise_cloud") {
                return onEnterprise;
              } else if (p.id === "production_v2") {
                // production v2 is like production_v3, except no index advisor
                if (feature === "pganalyze Index Advisor") {
                  return false;
                } else {
                  return onProd;
                }
              } else if (p.id === "scale_v2") {
                // scale v2 is like scale_v3/scale_v4, except no index advisor
                if (feature === "pganalyze Index Advisor") {
                  return false;
                } else {
                  return onScale;
                }
              } else if (p.id === "production") {
                // production v1 is like production_v3, except no index advisor, longer retention, and more servers
                if (feature === "pganalyze Index Advisor") {
                  return false;
                } else if (feature === "Support For x Database Servers") {
                  return "5 servers";
                } else if (feature === "Historic Statistics Retention Time") {
                  return "30 days";
                } else {
                  return onProd;
                }
              } else {
                if (typeof onProd === "boolean") {
                  return false;
                } else {
                  return "?";
                }
              }
            }),
          ];
        }),
      };
    }),
  };
}

export default PlanComparison;
