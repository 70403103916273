import React from "react";

import moment from "moment";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import Panel from "components/Panel";
import Loading from "components/Loading";
import { formatBytes } from "utils/format";

import { IssueDetail_getIssue as IssueType } from "../types/IssueDetail";

import QUERY from "./Query.graphql";
import {
  IndexAdvisorExistingIndexes as IndexAdvisorExistingIndexesType,
  IndexAdvisorExistingIndexesVariables,
  IndexAdvisorExistingIndexes_getSchemaTableIndices as ExistingIndexType,
} from "./types/IndexAdvisorExistingIndexes";
import { useRoutes } from "utils/routes";
import Grid from "components/Grid";

const IndexAdvisorExistingIndexes: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const issueTable = issue.references.find((ref) => ref.kind === "SchemaTable");
  const { data, loading, error } = useQuery<
    IndexAdvisorExistingIndexesType,
    IndexAdvisorExistingIndexesVariables
  >(QUERY, {
    variables: {
      databaseId: issue.databaseId,
      tableId: (issueTable.referent as { id: string }).id,
    },
  });
  const indexes = data?.getSchemaTableIndices;
  return (
    <Panel title={`Existing Indexes (${indexes?.length ?? "?"})`}>
      {loading || error ? (
        <Loading error={!!error} />
      ) : (
        <IndexAdvisorExistingIndexesTable
          databaseId={issue.databaseId}
          indexes={indexes}
        />
      )}
    </Panel>
  );
};

const IndexAdvisorExistingIndexesTable: React.FunctionComponent<{
  databaseId: string;
  indexes: ExistingIndexType[];
}> = ({ databaseId, indexes }) => {
  const { databaseIndex } = useRoutes();
  return (
    <Grid
      className="grid-cols-[minmax(20%,1fr),minmax(20%,1fr),minmax(10%,140px),minmax(5%,80px),minmax(10%,140px),minmax(10%,80px)]"
      striped
      data={indexes}
      defaultSortBy="indexDefShort"
      columns={[
        {
          field: "name",
          header: "Name",
          renderer: function NameCell({ rowData, fieldData }) {
            return (
              <Link to={databaseIndex(databaseId, rowData.id)}>
                {fieldData}
              </Link>
            );
          },
        },
        {
          field: "indexDefShort",
          header: "Definition",
        },
        {
          field: "constraintDef",
          header: "Constraint",
        },
        {
          field: "valid",
          header: "Valid?",
          renderer: function ValidCell({ fieldData }) {
            return fieldData ? "VALID" : "NOT VALID";
          },
        },
        {
          field: "firstSnapshotAt",
          header: "First Seen",
          nullValue: "Over 30 days ago",
          renderer: function FirstSeenCell({ fieldData }) {
            return moment.unix(fieldData).fromNow();
          },
        },
        {
          field: "sizeBytes",
          header: "Size",
          style: "number",
          nullValue: "?",
          renderer: function SizeCell({ fieldData }) {
            return formatBytes(fieldData);
          },
        },
      ]}
    />
  );
};

export default IndexAdvisorExistingIndexes;
