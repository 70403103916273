import { generatePath, UNSAFE_RouteContext, useParams } from "react-router-dom";
import isEqual from "lodash/isEqual";

import {
  useDateRange,
  encodeRange,
  useRouteRange,
} from "components/WithDateRange";
import { useContext } from "react";

export const useRoutes = () => {
  const [rangeParam] = useRouteRange();
  const [{ from, to, label }] = useDateRange();
  const withRange = (url: string): string => {
    if (rangeParam.default) {
      return url;
    }
    return url + "?t=" + encodeRange({ from, to, label });
  };
  return {
    database(databaseId: string): string {
      return withRange(`/databases/${databaseId}`);
    },
    databaseBackend(databaseId: string, backendId: string): string {
      return withRange(`/databases/${databaseId}/backends/${backendId}`);
    },
    databaseBackendLogs(databaseId: string, backendId: string): string {
      return withRange(`/databases/${databaseId}/backends/${backendId}/logs`);
    },
    databaseBackendQueries(databaseId: string, backendId: string): string {
      return withRange(
        `/databases/${databaseId}/backends/${backendId}/queries`,
      );
    },
    databaseBackendWaitEvents(databaseId: string, backendId: string): string {
      return withRange(
        `/databases/${databaseId}/backends/${backendId}/wait_events`,
      );
    },
    databaseBackends(databaseId: string): string {
      return withRange(`/databases/${databaseId}/backends`);
    },
    databaseBackendAtTime(
      databaseId: string,
      backendPid: number,
      ts: number,
    ): string {
      return withRange(`/databases/${databaseId}/backends/${backendPid}@${ts}`);
    },
    databaseBackendsAtTime(
      databaseId: string,
      from: number,
      to: number,
    ): string {
      return withRange(`/databases/${databaseId}/backends?t=${from}-${to}`);
    },
    databaseFunction(databaseId: string, functionId: string): string {
      return withRange(`/databases/${databaseId}/functions/${functionId}`);
    },
    databaseFunctions(databaseId: string): string {
      return withRange(`/databases/${databaseId}/functions`);
    },
    databaseIndex(databaseId: string, indexId: string): string {
      return withRange(`/databases/${databaseId}/indexes/${indexId}`);
    },
    databaseIndexCheck(databaseId: string, queryId: string): string {
      return withRange(
        `/databases/${databaseId}/queries/${queryId}/indexcheck`,
      );
    },
    databaseQueryIndexAdvisor(databaseId: string, queryId: string): string {
      return withRange(
        `/databases/${databaseId}/queries/${queryId}/indexadvisor`,
      );
    },
    databaseIndexAdvisor(databaseId: string): string {
      return withRange(`/databases/${databaseId}/indexadvisor`);
    },
    databaseIndexAdvisorMissingIndex(databaseId: string): string {
      return withRange(`/databases/${databaseId}/indexadvisor/missing_index`);
    },
    databaseIndexAdvisorIndexingEngine(databaseId: string): string {
      return withRange(`/databases/${databaseId}/indexadvisor/indexing_engine`);
    },
    databaseIndexAdvisorIndexUnused(databaseId: string): string {
      return withRange(`/databases/${databaseId}/indexadvisor/index_unused`);
    },
    databaseIndexAdvisorStatus(databaseId: string): string {
      return withRange(`/databases/${databaseId}/indexadvisor/status`);
    },
    databaseIndexAdvisorConfig(databaseId: string): string {
      return withRange(`/databases/${databaseId}/indexadvisor/config`);
    },
    databaseIndexes(databaseId: string): string {
      return withRange(`/databases/${databaseId}/indexes`);
    },
    databaseLogs(databaseId: string, logClassification?: string): string {
      const classificationPart = logClassification
        ? `/${logClassification}`
        : "";
      return withRange(`/databases/${databaseId}/logs${classificationPart}`);
    },
    databaseQueries(databaseId: string): string {
      return withRange(`/databases/${databaseId}/queries`);
    },
    databaseQueriesExplains(databaseId: string): string {
      return withRange(`/databases/${databaseId}/queries/explains`);
    },
    databaseQueriesSearch(databaseId: string): string {
      return withRange(`/databases/${databaseId}/queries/search`);
    },
    databaseQuery(databaseId: string, queryId: string): string {
      return withRange(`/databases/${databaseId}/queries/${queryId}`);
    },
    databaseQueryExplain(
      databaseId: string,
      queryId: string,
      explainId: string,
    ): string {
      return withRange(
        `/databases/${databaseId}/queries/${queryId}/explains/${explainId}`,
      );
    },
    databaseQueryExplains(databaseId: string, queryId: string): string {
      return withRange(`/databases/${databaseId}/queries/${queryId}/explains`);
    },
    databaseQueryLogs(databaseId: string, queryId: string): string {
      return withRange(`/databases/${databaseId}/queries/${queryId}/logs`);
    },
    databaseQuerySamples(databaseId: string, queryId: string): string {
      return withRange(`/databases/${databaseId}/queries/${queryId}/samples`);
    },
    databaseQueryTags(databaseId: string, queryId: string): string {
      return withRange(`/databases/${databaseId}/queries/${queryId}/tags`);
    },
    databaseReports(databaseId: string, report: "sequence" | "bloat"): string {
      return withRange(`/databases/${databaseId}/reports/${report}`);
    },
    databaseTable(databaseId: string, tableId: string): string {
      return withRange(`/databases/${databaseId}/tables/${tableId}`);
    },
    databaseTableColumns(databaseId: string, tableId: string): string {
      return withRange(`/databases/${databaseId}/tables/${tableId}/columns`);
    },
    databaseTableConstraints(databaseId: string, tableId: string): string {
      return withRange(
        `/databases/${databaseId}/tables/${tableId}/constraints`,
      );
    },
    databaseTableIndexes(databaseId: string, tableId: string): string {
      return withRange(`/databases/${databaseId}/tables/${tableId}/indexes`);
    },
    databaseTableIndexSelection(databaseId: string, tableId: string): string {
      return withRange(
        `/databases/${databaseId}/tables/${tableId}/index_advisor`,
      );
    },
    databaseTablePartitions(databaseId: string, tableId: string): string {
      return withRange(`/databases/${databaseId}/tables/${tableId}/partitions`);
    },
    databaseTableQueries(databaseId: string, tableId: string): string {
      return withRange(`/databases/${databaseId}/tables/${tableId}/queries`);
    },
    databaseTableVacuum(databaseId: string, tableId: string): string {
      return withRange(`/databases/${databaseId}/tables/${tableId}/vacuum`);
    },
    databaseTableVacuumSimulator(databaseId: string, tableId: string): string {
      return withRange(
        `/databases/${databaseId}/tables/${tableId}/vacuum_simulator`,
      );
    },
    databaseTables(databaseId: string): string {
      return withRange(`/databases/${databaseId}/tables`);
    },
    databaseView(databaseId: string, viewId: string): string {
      return withRange(`/databases/${databaseId}/views/${viewId}`);
    },
    databaseViewColumns(databaseId: string, viewId: string): string {
      return withRange(`/databases/${databaseId}/views/${viewId}/columns`);
    },
    databaseViewIndexes(databaseId: string, viewId: string): string {
      return withRange(`/databases/${databaseId}/views/${viewId}/indexes`);
    },
    databaseViews(databaseId: string): string {
      return withRange(`/databases/${databaseId}/views`);
    },
    databaseWaitEvents(databaseId: string): string {
      return withRange(`/databases/${databaseId}/wait_events`);
    },
    databaseChecks(databaseId: string): string {
      return withRange(`/databases/${databaseId}/checks`);
    },
    databaseCheck(
      databaseId: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(`/databases/${databaseId}/checks/${checkGroupAndName}`);
    },
    databaseCheckAcknowledged(
      databaseId: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/databases/${databaseId}/checks/${checkGroupAndName}/acknowledged`,
      );
    },
    databaseCheckResolved(
      databaseId: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/databases/${databaseId}/checks/${checkGroupAndName}/resolved`,
      );
    },
    databaseIssue(
      databaseId: string,
      issueId: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/databases/${databaseId}/checks/${checkGroupAndName}/${issueId}`,
      );
    },
    databaseVacuumsBloat(databaseId: string): string {
      return withRange(`/databases/${databaseId}/vacuums/bloat`);
    },
    databaseVacuumsActivity(databaseId: string): string {
      return withRange(`/databases/${databaseId}/vacuums/activity`);
    },
    databaseVacuumsFreezing(databaseId: string): string {
      return withRange(`/databases/${databaseId}/vacuums/freezing`);
    },

    organization(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}`);
    },
    organizationAlerts(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/alerts`);
    },
    organizationAlertPolicy(orgSlug: string, policyId: string): string {
      return withRange(`/organizations/${orgSlug}/alerts/policies/${policyId}`);
    },
    organizationApi(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/api`);
    },
    organizationDatabases(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/databases`);
    },
    organizationEdit(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/edit`);
    },
    organizationIntegrations(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/integrations`);
    },
    organizationIntegrationsPagerduty(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/integrations/pagerduty`);
    },
    organizationMember(orgSlug: string, memberId: string): string {
      return withRange(`/organizations/${orgSlug}/members/${memberId}`);
    },
    organizationMemberEdit(orgSlug: string, memberId: string): string {
      return withRange(`/organizations/${orgSlug}/members/${memberId}/edit`);
    },
    organizationMembers(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/members`);
    },
    organizationMembersNew(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/members/new`);
    },
    organizationResendInvite(orgSlug: string, memberId: string): string {
      return withRange(
        `/organizations/${orgSlug}/members/${memberId}/resend_invite`,
      );
    },
    organizationAcceptInvite(orgSlug: string, inviteToken: string): string {
      return withRange(
        `organizations/${orgSlug}/members/accept/${inviteToken}`,
      );
    },
    organizationRole(orgSlug: string, roleId: string): string {
      return withRange(`/organizations/${orgSlug}/roles/${roleId}`);
    },
    organizationRoleEdit(orgSlug: string, roleId: string): string {
      return withRange(`/organizations/${orgSlug}/roles/${roleId}/edit`);
    },
    organizationRoleNew(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/roles/new`);
    },
    organizationRoles(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/roles`);
    },
    organizationServers(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/servers`);
    },
    organizationServersInstall(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/servers/install`);
    },
    organizationServersSettings(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/servers/settings`);
    },
    organizationServersNew(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/servers/new`);
    },
    organizationSetup(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/setup`);
    },
    organizationSubscription(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/subscription`);
    },
    organizationSubscriptionInvoices(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/subscription#invoices`);
    },
    organizationSubscriptionComparePlans(orgSlug: string): string {
      return withRange(`/organizations/${orgSlug}/subscription/compare`);
    },
    organizationSubscriptionUpdateBillingDetails(orgSlug: string): string {
      return `/organizations/${orgSlug}/subscription/update_billing_details`;
    },
    organizationSubscriptionUpdateCreditCard(orgSlug: string): string {
      return `/organizations/${orgSlug}/subscription/update_credit_card`;
    },
    organizationSubscriptionActivate(orgSlug: string, planId: string): string {
      return `/organizations/${orgSlug}/subscription/activate/${planId}`;
    },
    organizationSubscriptionCancel(orgSlug: string): string {
      return `/organizations/${orgSlug}/subscription/cancel`;
    },
    organizationSubscriptionInvoice(
      orgSlug: string,
      reference: string,
    ): string {
      return `/organizations/${orgSlug}/subscription/invoices/${reference}`;
    },
    organizationCheck(
      organizationSlug: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/organizations/${organizationSlug}/checks/${checkGroupAndName}`,
      );
    },
    organizationCheckAcknowledged(
      organizationSlug: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/organizations/${organizationSlug}/checks/${checkGroupAndName}/acknowledged`,
      );
    },
    organizationCheckResolved(
      organizationSlug: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/organizations/${organizationSlug}/checks/${checkGroupAndName}/resolved`,
      );
    },
    organizationCheckConfigure(
      organizationSlug: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/organizations/${organizationSlug}/checks/${checkGroupAndName}/configure`,
      );
    },
    organizationChecks(organizationSlug: string): string {
      return withRange(`/organizations/${organizationSlug}/checks`);
    },
    organizationsNew(): string {
      return withRange("/organizations/new");
    },
    organizations(): string {
      return withRange(`/organizations`);
    },

    root(): string {
      return withRange("/");
    },

    server(serverId: string): string {
      return withRange(`/servers/${serverId}`);
    },
    serverIndexAdvisor(serverId: string): string {
      return withRange(`/servers/${serverId}/indexadvisor`);
    },
    serverQueries(serverId: string): string {
      return withRange(`/servers/${serverId}/queries`);
    },
    serverQueriesExplains(serverId: string): string {
      return withRange(`/servers/${serverId}/queries/explains`);
    },
    serverQueriesSearch(serverId: string): string {
      return withRange(`/servers/${serverId}/queries/search`);
    },
    serverSchema(serverId: string): string {
      return withRange(`/servers/${serverId}/schema`);
    },
    serverConnections(serverId: string): string {
      return withRange(`/servers/${serverId}/connections`);
    },
    serverEdit(serverId: string): string {
      return withRange(`/servers/${serverId}/edit`);
    },
    serverLogs(serverId: string, logClassification?: string): string {
      const classificationPart = logClassification
        ? `/${logClassification}`
        : "";
      return withRange(`/servers/${serverId}/logs${classificationPart}`);
    },
    serverReplication(serverId: string): string {
      return withRange(`/servers/${serverId}/replication`);
    },
    serverVacuum(serverId: string, vacuumId: string): string {
      return withRange(`/servers/${serverId}/vacuums/${vacuumId}`);
    },
    serverVacuums(serverId: string): string {
      return withRange(`/servers/${serverId}/vacuums`);
    },
    serverVacuumsActivity(serverId: string): string {
      return withRange(`/servers/${serverId}/vacuums/activity`);
    },
    serverVacuumsBloat(serverId: string): string {
      return withRange(`/servers/${serverId}/vacuums/bloat`);
    },
    serverVacuumsPerformance(serverId: string): string {
      return withRange(`/servers/${serverId}/vacuums/performance`);
    },
    serverVacuumsFreezing(serverId: string): string {
      return withRange(`/servers/${serverId}/vacuums/freezing`);
    },
    serverRole(serverId: string, roleId: string): string {
      return withRange(`/servers/${serverId}/roles/${roleId}`);
    },
    serverRoles(serverId: string): string {
      return withRange(`/servers/${serverId}/roles`);
    },
    serverConfig(serverId: string): string {
      return withRange(`/servers/${serverId}/config`);
    },
    serverConfigSetting(serverId: string, setting: string): string {
      return withRange(`/servers/${serverId}/config/${setting}`);
    },
    serverSystem(
      serverId: string,
      detail?: "cpu" | "memory" | "storage" | "network",
    ): string {
      const detailStr = detail === undefined ? "" : `/${detail}`;
      return withRange(`/servers/${serverId}/system${detailStr}`);
    },
    serverSetup(serverId: string): string {
      return withRange(`/servers/${serverId}/setup`);
    },
    serverSetupLogInsights(serverId: string): string {
      return withRange(`/servers/${serverId}/setup/log-insights`);
    },
    serverSetupAutomatedExplain(serverId: string): string {
      return withRange(`/servers/${serverId}/setup/explain`);
    },
    serverSnapshot(serverId: string, snapshotId: string): string {
      return withRange(`/servers/${serverId}/snapshots/${snapshotId}`);
    },
    serverSnapshots(serverId: string): string {
      return withRange(`/servers/${serverId}/snapshots`);
    },
    serverAlerts(serverId: string): string {
      return withRange(`/servers/${serverId}/alerts`);
    },
    serverAlertConfig(serverId: string): string {
      return withRange(`/servers/${serverId}/alerts/configs`);
    },
    serverChecks(serverId: string): string {
      return withRange(`/servers/${serverId}/checks`);
    },
    serverCheck(
      serverId: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(`/servers/${serverId}/checks/${checkGroupAndName}`);
    },
    serverCheckAcknowledged(
      serverId: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/servers/${serverId}/checks/${checkGroupAndName}/acknowledged`,
      );
    },
    serverCheckResolved(
      serverId: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/servers/${serverId}/checks/${checkGroupAndName}/resolved`,
      );
    },
    serverCheckConfigure(
      serverId: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/servers/${serverId}/checks/${checkGroupAndName}/configure`,
      );
    },
    serverIssue(
      serverId: string,
      issueId: string,
      checkGroupOrShorthand: string,
      checkName?: string,
    ): string {
      const checkGroupAndName =
        checkName !== undefined
          ? checkGroupOrShorthand + "/" + checkName
          : checkGroupOrShorthand;
      return withRange(
        `/servers/${serverId}/checks/${checkGroupAndName}/${issueId}`,
      );
    },

    users(): string {
      return withRange("/users");
    },
    userEdit(): string {
      return withRange("/users/edit");
    },
  };
};

export const useUnauthenticatedRoutes = () => {
  return {
    users(): string {
      return "/users";
    },
    userSignUp(): string {
      return "/users/sign_up";
    },
    userSignIn(): string {
      return "/users/sign_in";
    },
    userPasswordReset(): string {
      return "/users/password/new";
    },
  };
};

// Returns a route thats conceptually similar as the current route, but for a different organization/server/database.
//
// Note that this also supports going across different reference types (e.g. query perf view for a particular server
// to query perf view for some database ID), if available. Falls back to the root path for the particular type if
// no match can be made.
export const useRelatedRoute = () => {
  const params = useParams();

  // TODO: There might be a way to avoid direct Router context access with some
  // refactoring - this is done like this for now to aid with migration from
  // React Router v5 (which allowed access to the route path), and v6 (which
  // does not appear to allow access)
  const matches = useContext(UNSAFE_RouteContext).matches;
  const pathname = matches
    .map((m) => m.route.path)
    .filter((p) => p != undefined)
    .join("/");

  const {
    server: serverRoot,
    database: databaseRoot,
    organization: organizationRoot,
  } = useRoutes();
  const [rangeParam] = useRouteRange();
  const [{ from, to, label }] = useDateRange();
  const withRange = (url: string): string => {
    if (rangeParam.default) {
      return url;
    }
    return url + "?t=" + encodeRange({ from, to, label });
  };

  const relatedDatabaseRoute = (databaseId: string): string => {
    if (!params || !pathname) {
      return databaseRoot(databaseId);
    }

    if (
      isEqual(Object.keys(params), ["databaseId"]) ||
      isEqual(Object.keys(params), ["databaseId", "checkGroup", "checkName"]) ||
      isEqual(Object.keys(params), ["databaseId", "classification"])
    ) {
      return withRange(generatePath(pathname, { ...params, databaseId }));
    }
    const mappedPath = mapToRelatedDatabasePath(pathname);
    if (mappedPath) {
      return withRange(
        generatePath(mappedPath, {
          ...params,
          databaseId,
        }),
      );
    }

    return databaseRoot(databaseId);
  };

  const relatedServerRoute = (serverId: string): string => {
    if (!params || !pathname) {
      return serverRoot(serverId);
    }

    if (
      isEqual(Object.keys(params), ["serverId"]) ||
      isEqual(Object.keys(params), ["serverId", "checkGroup", "checkName"]) ||
      isEqual(Object.keys(params), ["serverId", "classification"]) ||
      isEqual(Object.keys(params), ["serverId", "settingName"])
    ) {
      return withRange(generatePath(pathname, { ...params, serverId }));
    }
    const mappedPath = mapToRelatedServerPath(pathname);
    if (mappedPath) {
      return withRange(
        generatePath(mappedPath, {
          ...params,
          serverId,
        }),
      );
    }

    return serverRoot(serverId);
  };

  const relatedOrganizationRoute = (slug: string): string => {
    if (!params || !pathname) {
      return organizationRoot(slug);
    }

    if (
      isEqual(Object.keys(params), ["slug"]) ||
      isEqual(Object.keys(params), ["slug", "checkGroup", "checkName"])
    ) {
      return withRange(generatePath(pathname, { ...params, slug }));
    }
    const mappedPath = mapToRelatedOrganizationPath(pathname);
    if (mappedPath) {
      return withRange(
        generatePath(mappedPath, {
          ...params,
          slug,
        }),
      );
    }

    return organizationRoot(slug);
  };

  return {
    relatedDatabaseRoute,
    relatedServerRoute,
    relatedOrganizationRoute,
  };
};

// Source paths that can map to a certain target database path
const mapToRelatedDatabasePath = (path: string): string | null => {
  switch (path) {
    case "/servers/:serverId/queries":
    case "/servers/:serverId/queries/explains":
    case "/servers/:serverId/queries/search":
    case "/servers/:serverId/logs":
    case "/servers/:serverId/logs/:classification":
    case "/servers/:serverId/checks":
    case "/servers/:serverId/checks/:checkGroup/:checkName":
    case "/servers/:serverId/checks/:checkGroup/:checkName/acknowledged":
    case "/servers/:serverId/checks/:checkGroup/:checkName/resolved":
    case "/servers/:serverId/checks/:checkGroup/:checkName/configure":
    case "/servers/:serverId/indexadvisor":
    case "/servers/:serverId/vacuums/activity":
    case "/servers/:serverId/vacuums/bloat":
    case "/servers/:serverId/vacuums/freezing":
      return path.replace("/servers/:serverId/", "/databases/:databaseId/");
    case "/servers/:serverId/schema":
      return "/databases/:databaseId/tables";
    case "/servers/:serverId/connections":
      return "/databases/:databaseId/backends";
    case "/organizations/:slug/checks":
    case "/organizations/:slug/checks/:checkGroup/:checkName":
    case "/organizations/:slug/checks/:checkGroup/:checkName/acknowledged":
    case "/organizations/:slug/checks/:checkGroup/:checkName/resolved":
    case "/organizations/:slug/checks/:checkGroup/:checkName/configure":
      return path.replace("/organizations/:slug/", "/databases/:databaseId/");
    // Multi-parameter paths that should go to the top-level within the database context
    case "/databases/:databaseId/queries/:queryId":
    case "/databases/:databaseId/queries/:queryId/indexcheck":
    case "/databases/:databaseId/queries/:queryId/samples":
    case "/databases/:databaseId/queries/:queryId/explains":
    case "/databases/:databaseId/queries/:queryId/explains/:explainId":
    case "/databases/:databaseId/queries/:queryId/tags":
    case "/databases/:databaseId/queries/:queryId/logs":
      return "/databases/:databaseId/queries";
    case "/databases/:databaseId/tables/:tableId":
    case "/databases/:databaseId/tables/:tableId/partitions":
    case "/databases/:databaseId/tables/:tableId/queries":
    case "/databases/:databaseId/tables/:tableId/columns":
    case "/databases/:databaseId/tables/:tableId/indexes":
    case "/databases/:databaseId/tables/:tableId/constraints":
    case "/databases/:databaseId/tables/:tableId/vacuum":
      return "/databases/:databaseId/tables";
    case "/databases/:databaseId/indexes/:schemaIndexId":
      return "/databases/:databaseId/indexes";
    case "/databases/:databaseId/views/:viewId":
    case "/databases/:databaseId/views/:viewId/columns":
    case "/databases/:databaseId/views/:viewId/indexes":
      return "/databases/:databaseId/views";
    case "/databases/:databaseId/functions/:schemaFunctionId":
      return "/databases/:databaseId/functions";
    case "/databases/:databaseId/backends/:locator":
    case "/databases/:databaseId/backends/:locator/queries":
    case "/databases/:databaseId/backends/:locator/wait_events":
    case "/databases/:databaseId/backends/:locator/logs":
      return "/databases/:databaseId/backends";
  }
  return null;
};

// Source paths that can map to a certain target server path
const mapToRelatedServerPath = (path: string): string | null => {
  switch (path) {
    case "/databases/:databaseId/queries":
    case "/databases/:databaseId/queries/explains":
    case "/databases/:databaseId/queries/search":
    case "/databases/:databaseId/logs":
    case "/databases/:databaseId/logs/:classification":
    case "/databases/:databaseId/checks":
    case "/databases/:databaseId/checks/:checkGroup/:checkName":
    case "/databases/:databaseId/checks/:checkGroup/:checkName/acknowledged":
    case "/databases/:databaseId/checks/:checkGroup/:checkName/resolved":
    case "/databases/:databaseId/checks/:checkGroup/:checkName/configure":
    case "/databases/:databaseId/indexadvisor":
    case "/databases/:databaseId/vacuums/activity":
    case "/databases/:databaseId/vacuums/bloat":
    case "/databases/:databaseId/vacuums/freezing":
      return path.replace("/databases/:databaseId/", "/servers/:serverId/");
    case "/databases/:databaseId/queries/:queryId":
    case "/databases/:databaseId/queries/:queryId/indexcheck":
    case "/databases/:databaseId/queries/:queryId/samples":
    case "/databases/:databaseId/queries/:queryId/explains":
    case "/databases/:databaseId/queries/:queryId/explains/:explainId":
    case "/databases/:databaseId/queries/:queryId/tags":
    case "/databases/:databaseId/queries/:queryId/logs":
      return "/servers/:serverId/queries";
    case "/databases/:databaseId/tables":
    case "/databases/:databaseId/tables/:tableId":
    case "/databases/:databaseId/tables/:tableId/partitions":
    case "/databases/:databaseId/tables/:tableId/queries":
    case "/databases/:databaseId/tables/:tableId/columns":
    case "/databases/:databaseId/tables/:tableId/indexes":
    case "/databases/:databaseId/tables/:tableId/constraints":
    case "/databases/:databaseId/tables/:tableId/vacuum":
    case "/databases/:databaseId/indexes":
    case "/databases/:databaseId/indexes/:schemaIndexId":
    case "/databases/:databaseId/views":
    case "/databases/:databaseId/views/:viewId":
    case "/databases/:databaseId/views/:viewId/columns":
    case "/databases/:databaseId/views/:viewId/indexes":
    case "/databases/:databaseId/functions":
    case "/databases/:databaseId/functions/:schemaFunctionId":
      return "/servers/:serverId/schema";
    case "/databases/:databaseId/backends":
    case "/databases/:databaseId/backends/:locator":
    case "/databases/:databaseId/backends/:locator/queries":
    case "/databases/:databaseId/backends/:locator/wait_events":
    case "/databases/:databaseId/backends/:locator/logs":
    case "/databases/:databaseId/wait_events":
      return "/servers/:serverId/connections";
    case "/organizations/:slug/checks":
    case "/organizations/:slug/checks/:checkGroup/:checkName":
    case "/organizations/:slug/checks/:checkGroup/:checkName/acknowledged":
    case "/organizations/:slug/checks/:checkGroup/:checkName/resolved":
    case "/organizations/:slug/checks/:checkGroup/:checkName/configure":
    case "/organizations/:slug/alerts":
      return path.replace("/organizations/:slug/", "/servers/:serverId/");
    case "/organizations/:slug/servers/settings":
      return "/servers/:serverId/edit";
    // Multi-parameter paths that should go to the top-level within the database context
    case "/servers/:serverId/vacuums/:vacuumRunIdentity":
      return "/servers/:serverId/vacuums";
    case "/servers/:serverId/roles/:id":
      return "/servers/:serverId/roles";
    case "/servers/:serverId/snapshots/:snapshotId":
      return "/servers/:serverId/snapshots";
  }
  return null;
};

// Source paths that can map to a certain target organization path
const mapToRelatedOrganizationPath = (path: string): string | null => {
  switch (path) {
    case "/databases/:databaseId/checks":
    case "/databases/:databaseId/checks/:checkGroup/:checkName":
    case "/databases/:databaseId/checks/:checkGroup/:checkName/acknowledged":
    case "/databases/:databaseId/checks/:checkGroup/:checkName/resolved":
    case "/databases/:databaseId/checks/:checkGroup/:checkName/configure":
      return path.replace("/databases/:databaseId/", "/organizations/:slug/");
    case "/servers/:serverId/checks":
    case "/servers/:serverId/checks/:checkGroup/:checkName":
    case "/servers/:serverId/checks/:checkGroup/:checkName/acknowledged":
    case "/servers/:serverId/checks/:checkGroup/:checkName/resolved":
    case "/servers/:serverId/checks/:checkGroup/:checkName/configure":
    case "/servers/:serverId/alerts":
      return path.replace("/servers/:serverId/", "/organizations/:slug/");
    case "/servers/:serverId/edit":
      return "/organizations/:slug/servers/settings";
    // Multi-parameter paths that should go to the top-level within the organization context
    case "/organizations/:slug/alerts/policies/:alertPolicyId":
      return "/organizations/:slug/alerts";
    case "/organizations/:slug/members/:id":
    case "/organizations/:slug/members/:id/edit":
      return "/organizations/:slug/members";
    case "/organizations/:slug/roles/:id":
    case "/organizations/:slug/roles/:id/edit":
      return "/organizations/:slug/roles";
    case "/organizations/:slug/subscription/activate/:plan_id":
      return "/organizations/:slug/subscription";
  }

  return null;
};
