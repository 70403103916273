import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

import { useUserPreferences } from "utils/hooks";

import { PageHeaderNav_servers as ServerType } from "./types/PageHeaderNav";

import styles from "./style.module.scss";
import { useRelatedRoute, useRoutes } from "utils/routes";
import { mru } from "utils/array";
import Dropdown from "components/Dropdown";
import { CaretIcon, StatusDotIcon } from "components/Icons";
import { faEllipsisV, faPlus } from "@fortawesome/pro-solid-svg-icons";

type Props = {
  servers: Array<ServerType>;
  currentServerId?: string;
  organizationSlug: string;
};

const ServerSelect: React.FunctionComponent<Props> = ({
  servers,
  currentServerId,
  organizationSlug,
}) => {
  const [serverSelect, setServerSelect] = useUserPreferences("serverSelect");
  const recentServerIds = useMemo(() => {
    return (serverSelect.recentServerIds || {})[organizationSlug] || [];
  }, [serverSelect.recentServerIds, organizationSlug]);
  const { relatedOrganizationRoute, relatedServerRoute } = useRelatedRoute();

  useEffect(() => {
    if (currentServerId && recentServerIds[0] != currentServerId) {
      setServerSelect({
        ...serverSelect,
        recentServerIds: {
          ...serverSelect.recentServerIds,
          [organizationSlug]: mru(currentServerId, recentServerIds, 5),
        },
      });
    }
  }, [
    setServerSelect,
    serverSelect,
    organizationSlug,
    recentServerIds,
    currentServerId,
  ]);

  let topServers = servers.slice();
  topServers.sort((a, b) => {
    if (
      recentServerIds.includes(a.humanId) &&
      recentServerIds.includes(b.humanId)
    ) {
      return (
        recentServerIds.indexOf(a.humanId) - recentServerIds.indexOf(b.humanId)
      );
    }
    if (recentServerIds.includes(a.humanId)) {
      return -1;
    }
    if (recentServerIds.includes(b.humanId)) {
      return 1;
    }
    if (a.hasRecentSnapshot && !b.hasRecentSnapshot) {
      return -1;
    }
    if (!a.hasRecentSnapshot && b.hasRecentSnapshot) {
      return 1;
    }
    return 0;
  });
  topServers = topServers.slice(0, 5);

  const extraServers = servers.length - topServers.length;

  const currentServer = servers.find(
    (s: ServerType): boolean => s.humanId === currentServerId,
  );

  const trigger = ({
    open,
    toggleOpen,
  }: {
    open: boolean;
    toggleOpen: () => void;
  }) => (
    <button onClick={toggleOpen}>
      <small>Server</small>
      <div className={styles.selectMenuCurrent}>
        {currentServer ? (
          <>
            <ServerItemContent server={currentServer} />
            <Link
              to={relatedOrganizationRoute(organizationSlug)}
              className={styles.closeLink}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Link>
          </>
        ) : (
          <>
            <StatusDotIcon status="no data" title="" fixedWidth />
            <span className="ml-[4px]">(no server)</span>
          </>
        )}
        <CaretIcon direction={open ? "up" : "down"} headerNav />
      </div>
    </button>
  );

  return (
    <li className={styles.selectMenu} data-select-menu="server">
      <Dropdown trigger={trigger} className={styles.dropdown}>
        {({ setClosed }) => {
          return (
            <ul className={styles.selectMenuList}>
              {topServers.map((s) => (
                <li key={s.id}>
                  <Link
                    className={styles.selectMenuListItem}
                    to={relatedServerRoute(s.humanId)}
                    onClick={setClosed}
                  >
                    <ServerItemContent server={s} />
                  </Link>
                </li>
              ))}
              {extraServers > 0 && (
                <ExtraServersItem
                  extraServers={extraServers}
                  organizationSlug={organizationSlug}
                  setClosed={setClosed}
                />
              )}
              <AddServer organizationSlug={organizationSlug} />
            </ul>
          );
        }}
      </Dropdown>
    </li>
  );
};

const ExtraServersItem: React.FunctionComponent<{
  extraServers: number;
  organizationSlug: string;
  setClosed: () => void;
}> = ({ organizationSlug, extraServers, setClosed }) => {
  const { organizationServers } = useRoutes();
  return (
    <li>
      <Link
        className={styles.selectMenuListItem}
        to={organizationServers(organizationSlug)}
        onClick={() => {
          setClosed();
        }}
      >
        <FontAwesomeIcon
          icon={faEllipsisV}
          className="text-[10px] mr-[2px]"
          fixedWidth
        />
        <span className="ml-[4px]">Show {extraServers} more</span>
      </Link>
    </li>
  );
};

const AddServer: React.FunctionComponent<{ organizationSlug: string }> = ({
  organizationSlug,
}) => {
  const { organizationServersNew } = useRoutes();
  return (
    <li key="new">
      <Link
        className={styles.selectMenuListItem}
        to={organizationServersNew(organizationSlug)}
      >
        <FontAwesomeIcon
          icon={faPlus}
          className="text-[10px] mr-[2px] hover:text-[#000] hover:font-medium active:text-[#000] active:font-medium"
          fixedWidth
        />
        <span className="ml-[4px] hover:text-[#000] hover:font-medium active:text-[#000] active:font-medium">
          Add Server
        </span>
      </Link>
    </li>
  );
};

const ServerItemContent: React.FunctionComponent<{ server: ServerType }> = ({
  server,
}) => {
  const status = server.hasRecentSnapshot
    ? server.hasCriticalIssues
      ? "critical"
      : "okay"
    : "no data";
  return (
    <>
      <StatusDotIcon status={status} fixedWidth />
      <span key="name" className="ml-[4px]">
        {server.name}
      </span>
    </>
  );
};

export default ServerSelect;
