import React from "react";

import { Link } from "react-router-dom";

import Panel from "components/Panel";
import PanelTable from "components/PanelTable";
import Grid, { NumberCell } from "components/Grid";
import IndexReference from "../IndexReference";

import { formatMs, formatNumber, formatPercent } from "utils/format";
import { useRoutes } from "utils/routes";
import { IndexSelectionResultType, ScanType } from "../util";
import { IndexIcon } from "../Icons";
import { useFeature } from "components/OrganizationFeatures";

const ScanDetails: React.FunctionComponent<{
  databaseId: string;
  result: IndexSelectionResultType;
  scan: ScanType;
}> = ({ databaseId, result, scan }) => {
  return (
    <div>
      <ScanInfoPanel result={result} scan={scan} />
      <ScanAffectedQueries key={scan.id} databaseId={databaseId} scan={scan} />
      <ConsideredIndexesPanel result={result} scan={scan} />
    </div>
  );
};

const ScanInfoPanel: React.FunctionComponent<{
  result: IndexSelectionResultType;
  scan: ScanType;
}> = ({ result, scan }) => {
  const currCost = scan.bestExistingCost;
  const currIdx = result.indexes[currCost.indexId];
  const newCost = scan.bestSelectedCost;
  const newIdx = result.indexes[newCost.indexId];

  const whereClauseStr = scan.whereExpression ? (
    <span className="font-mono">{scan.whereExpression}</span>
  ) : (
    <>&mdash;</>
  );
  const joinClauseStr = scan.joinExpression ? (
    <span className="font-mono">{scan.joinExpression}</span>
  ) : (
    <>&mdash;</>
  );

  return (
    <Panel title="Scan Details">
      <PanelTable horizontal borders>
        <tbody>
          <tr>
            <th>WHERE</th>
            <td colSpan={3}>{whereClauseStr}</td>
          </tr>
          <tr>
            <th>JOIN</th>
            <td colSpan={3}>{joinClauseStr}</td>
          </tr>
          <tr>
            <th>Est. Scans / Min</th>
            <td className="text-right">{formatNumber(scan.scansPerMin, 2)}</td>
            <th></th>
            <td></td>
          </tr>
          <tr>
            <th>Current Est. Cost</th>
            <td className="text-right">{formatNumber(currCost.cost)}</td>
            <th>Current Index</th>
            <td>
              <IndexReference index={currIdx} />
            </td>
          </tr>
          <tr>
            <th>New Est. Cost</th>
            <td className="text-right">
              {newIdx?.id == currIdx?.id ? (
                <>no change</>
              ) : (
                formatNumber(newCost.cost)
              )}
            </td>
            <th>New Index</th>
            <td>
              {newIdx?.id == currIdx?.id ? (
                <>no change</>
              ) : (
                <IndexReference index={newIdx} />
              )}
            </td>
          </tr>
        </tbody>
      </PanelTable>
    </Panel>
  );
};

const ConsideredIndexesPanel: React.FunctionComponent<{
  result: IndexSelectionResultType;
  scan: ScanType;
}> = ({ result, scan }) => {
  const bestCost = Math.min.apply(
    null,
    scan.executionCosts.map((cost) => cost.cost),
  );
  const considered = scan.executionCosts.map((cost) => {
    const index = result.indexes[cost.indexId];
    return {
      ...cost,
      index,
      structure: index?.structure ?? "Seq. Scan",
      indexWriteOverhead: index?.writeOverhead,
      estCost: cost.cost,
      relativeCost: cost.cost / bestCost,
      weightedCost: cost.cost * scan.scansPerMin,
    };
  });
  return (
    <Panel title="Considered Indexes" secondaryTitle={<IndexIconLegend />}>
      <Grid
        className="grid-cols-[1fr_15%_10%_15%_15%]"
        defaultSortBy={["estCost", "indexWriteOverhead"]}
        data={considered}
        columns={[
          {
            field: "structure",
            header: "Definition",
            renderer: function IndexDefinitionCell({ rowData }) {
              return (
                <IndexReference className="max-w-full" index={rowData.index} />
              );
            },
          },
          {
            field: "indexWriteOverhead",
            header: "Index Write Overhead",
            style: "number",
            renderer: NumberCell.precision(2),
            nullValue: "n/a",
          },
          {
            field: "estCost",
            header: "Est. Cost",
            style: "number",
            renderer: NumberCell,
          },
          {
            field: "relativeCost",
            header: "Relative Cost",
            tip: (
              <>
                This represents how the cost of this scan using a given index
                compares to the best possible cost for this scan.
              </>
            ),
            style: "number",
            renderer: function RelativeCostCell({ fieldData }) {
              return `×${formatNumber(fieldData, 2)}`;
            },
          },
          {
            field: "weightedCost",
            header: "Weighted Cost",
            tip: (
              <>
                Expected effect of the execution of this scan on your database,
                based on its cost and frequency (<code>Est. Scans/min</code> ×{" "}
                <code>Est. New Cost</code>). Like with cost, the absolute value
                is not meaningful, but you can use relative differences between
                weighted cost to compare different scans.
              </>
            ),
            style: "number",
            renderer: NumberCell.precision(2),
          },
        ]}
      />
    </Panel>
  );
};

const IndexIconLegend: React.FunctionComponent = () => {
  const useConsolidation = useFeature("indexAdvisorV3Consolidation");
  return (
    <div className="flex gap-5 text-sm items-start">
      <span className="flex">
        <IndexIcon
          className="mr-0.5 scale-75 relative -top-[1px]"
          label=""
          selected
        />{" "}
        Create Index
      </span>
      {useConsolidation && (
        <span className="flex">
          <IndexIcon
            className="mr-0.5 scale-75 relative -top-[1px]"
            label=""
            existing
          />{" "}
          Drop Index
        </span>
      )}
      <span className="flex">
        <IndexIcon
          className="mr-0.5 scale-75 relative -top-[1px]"
          label=""
          existing
          selected
        />{" "}
        Existing Index
      </span>
      <span className="flex">
        <IndexIcon
          className="mr-0.5 scale-75 border-2 relative -top-[1px]"
          label=""
        />{" "}
        Considered Index
      </span>
    </div>
  );
};

const ScanAffectedQueries: React.FunctionComponent<{
  databaseId: string;
  scan: ScanType;
}> = ({ databaseId, scan }) => {
  const { databaseQuery } = useRoutes();
  return (
    <Panel title="Affected Queries">
      <Grid
        className="grid-cols-[64%_12%_12%_12%]"
        striped
        data={scan.queries}
        columns={[
          {
            field: "truncatedQuery",
            header: "Query",
            style: "query",
            renderer: function QueryTextCell({ fieldData, rowData }) {
              const url = databaseQuery(databaseId, rowData.id);
              return (
                <Link title={rowData.normalizedQuery} to={url}>
                  {fieldData}
                </Link>
              );
            },
          },
          {
            field: "avgTime",
            header: "Avg. Time (ms)",
            style: "number",
            renderer: function AvgTimeCell({ fieldData }) {
              return formatMs(fieldData, 2);
            },
          },
          {
            field: "callsPerMinute",
            header: "Calls / Min",
            style: "number",
            renderer: function AvgTimeCell({ fieldData }) {
              return formatNumber(fieldData, 3);
            },
          },
          {
            field: "pctOfTotal",
            header: "% All Runtime",
            style: "number",
            renderer: function AvgTimeCell({ fieldData }) {
              return formatPercent(fieldData / 100, 2);
            },
          },
        ]}
      />
    </Panel>
  );
};

export default ScanDetails;
