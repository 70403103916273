import React from "react";

import IndexAdvisorIssueSummaryCard, {
  IssueType,
} from "components/IndexAdvisorIssueSummaryCard";

import styles from "../IndexAdvisorIssueSummaryCard/style.module.scss";

type ListProps = {
  issues: IssueType[];
  showTable?: boolean;
};

export const IndexAdvisorIssueSummaryList: React.FunctionComponent<
  ListProps
> = ({ issues, showTable }) => {
  return (
    <div className={styles.list}>
      {issues.map((issue) => {
        return (
          <IndexAdvisorIssueSummaryCard
            key={issue.id}
            issue={issue}
            showTable={showTable}
          />
        );
      })}
    </div>
  );
};

export default IndexAdvisorIssueSummaryList;
