import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { SortDirection } from "types/graphql-global-types";

import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";

import {
  formatBytes,
  formatPercent,
  formatNumber,
  formatBytesTrend,
  formatBytesTrendDetail,
} from "utils/format";
import Loading from "components/Loading";

import {
  SchemaTableList,
  SchemaTableListVariables,
  SchemaTableList_getSchemaTables,
  SchemaTableList_getSchemaTables_issues,
} from "./types/SchemaTableList";
import { useRoutes } from "utils/routes";

import styles from "./style.module.scss";
import QUERY from "./Query.graphql";
import LazyLoader from "components/LazyLoader";
import { GridColumn, SortOrder } from "components/Grid/util";
import { toSortDir, toSortOrder } from "utils/graphql";
import Grid from "components/Grid";

interface SortOptsType {
  sortBy: string;
  sortDirection: SortDirection;
}

interface Props {
  databaseId: string;
  parentTableId?: string;
}

const Table: React.FunctionComponent<Props> = ({
  databaseId,
  parentTableId,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [includePartitions, setIncludePartitions] = useState(false);

  const secondaryTitle = (
    <>
      {!parentTableId && (
        <div className={styles.showIncludePartitions}>
          <input
            type="checkbox"
            checked={includePartitions}
            id="include_partitions"
            onChange={(evt) => setIncludePartitions(evt.target.checked)}
          />
          <label htmlFor="include_partitions">Show table partitions</label>
        </div>
      )}
      <PanelTitleSearch value={searchTerm} onChange={setSearchTerm} />
    </>
  );

  return (
    <Panel
      title={parentTableId ? "Table Partitions" : "All Tables"}
      secondaryTitle={secondaryTitle}
    >
      <TableContent
        databaseId={databaseId}
        parentTableId={parentTableId}
        searchTerm={searchTerm}
        includePartitions={includePartitions}
      />
    </Panel>
  );
};

const FETCH_BATCH_SIZE = 50;

const TableContent: React.FunctionComponent<{
  databaseId: string;
  parentTableId: string | undefined;
  searchTerm: string;
  includePartitions: boolean;
}> = ({ databaseId, parentTableId, searchTerm, includePartitions }) => {
  const [sortOpts, setSortOpts] = useState<SortOptsType>({
    sortBy: parentTableId ? "tableName" : "sizeBytes",
    sortDirection: SortDirection.DESC,
  });

  const { data, loading, error, fetchMore } = useQuery<
    SchemaTableList,
    SchemaTableListVariables
  >(QUERY, {
    variables: {
      offset: 0,
      limit: FETCH_BATCH_SIZE,
      filter: searchTerm,
      sortBy: sortOpts.sortBy,
      sortDirection: sortOpts.sortDirection,
      databaseId: databaseId,
      parentTableId: parentTableId,
      includePartitions: parentTableId ? null : includePartitions,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const schemaTables = data!.getSchemaTables;

  const fetchMoreData = (loadedCount: number) => {
    return new Promise<boolean>((resolve) => {
      const offset = loadedCount;
      fetchMore({
        variables: { offset, limit: FETCH_BATCH_SIZE },
        updateQuery: (prev, { fetchMoreResult }): SchemaTableList => {
          const fetched = fetchMoreResult.getSchemaTables;
          resolve(fetched.length > 0);
          if (!prev || !prev.getSchemaTables) {
            return fetchMoreResult;
          }
          return {
            ...prev,
            getSchemaTables: [...prev.getSchemaTables, ...fetched],
          };
        },
      });
    });
  };

  function handleSort(
    sortBy: keyof SchemaTableList_getSchemaTables,
    sortOrder: SortOrder,
  ) {
    setSortOpts({
      sortBy,
      sortDirection: toSortDir(sortOrder),
    });
  }

  const gridData = data!.getSchemaTables.map((t) => {
    const { lastStats, stats7dAgo } = t;
    const {
      dataSizeBytes: sizeBytes,
      snapshotAt,
      liveTuples,
      deadTupleRatio,
      indexSizeBytes,
    } = lastStats ?? {};
    const { dataSizeBytes: sizeBytes7dAgo, snapshotAt: snapshotAt7dAgo } =
      stats7dAgo ?? {};
    const sizeBytesTrend =
      sizeBytes != null && sizeBytes7dAgo != null
        ? sizeBytes - sizeBytes7dAgo
        : undefined;

    return {
      ...t,
      liveTuples,
      deadTupleRatio,
      sizeBytes,
      snapshotAt,
      sizeBytes7dAgo,
      snapshotAt7dAgo,
      sizeBytesTrend,
      indexSizeBytes,
    };
  });

  const columns: GridColumn<
    (typeof gridData)[number],
    keyof (typeof gridData)[number]
  >[] = [];

  if (!parentTableId) {
    columns.push({
      field: "schemaName",
      header: "Schema",
    });
  }
  columns.push({
    field: "tableName",
    header: "Name",
    nullValue: "n/a",
    renderer: function TableNameCell({ rowData }) {
      return (
        <NameAndIssuesCell databaseId={databaseId} schemaTable={rowData} />
      );
    },
  });
  if (parentTableId) {
    columns.push({
      field: "partitionBoundary",
      header: "Partition Boundary",
      style: "query",
    });
  }
  columns.push(
    {
      field: "liveTuples",
      header: "Est. Visible Rows",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: function LiveTuplesCell({ fieldData }) {
        return formatNumber(fieldData);
      },
    },
    {
      field: "deadTupleRatio",
      header: "Dead Row %",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: function DeadTupleRatioCell({ fieldData }) {
        return formatPercent(fieldData);
      },
    },
    {
      field: "sizeBytes",
      header: "Data Size",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: function SizeBytesCell({ fieldData }) {
        return formatBytes(fieldData);
      },
    },
    {
      field: "sizeBytesTrend",
      header: "7 Day Trend",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: function SizeBytesTrendCell({ rowData }) {
        const { sizeBytes, sizeBytes7dAgo, snapshotAt, snapshotAt7dAgo } =
          rowData;
        if (!sizeBytes || !sizeBytes7dAgo || !snapshotAt || !snapshotAt7dAgo) {
          return "n/a";
        }
        const content = formatBytesTrend(sizeBytes, sizeBytes7dAgo);
        const tooltip = formatBytesTrendDetail(
          sizeBytes,
          sizeBytes7dAgo,
          snapshotAt,
          snapshotAt7dAgo,
        );
        return <span title={tooltip}>{content}</span>;
      },
    },
    {
      field: "indexSizeBytes",
      header: "Index Size",
      style: "number",
      defaultSortOrder: "desc",
      nullValue: "n/a",
      renderer: function IndexSizeBytesCell({ fieldData }) {
        return formatBytes(fieldData);
      },
    },
  );

  const sortedOrder = toSortOrder(sortOpts.sortDirection);
  const sortedBy = sortOpts.sortBy as keyof SchemaTableList_getSchemaTables;

  const gridClassname = parentTableId
    ? "grid-cols-[2fr_3fr_min(15%,140px)_min(10%,120px)_min(10%,120px)_min(20%,200px)_min(10%,120px)]"
    : "grid-cols-[80px_1fr_15%_10%_12%_20%_10%]";

  return (
    <LazyLoader loadedCount={schemaTables.length} loadMore={fetchMoreData}>
      <Grid
        className={gridClassname}
        striped
        data={gridData}
        columns={columns}
        sortedBy={sortedBy}
        sortedOrder={sortedOrder}
        noRowsText={
          parentTableId
            ? "No partitions or table inheritance children found"
            : "No tables found"
        }
        handleSort={handleSort}
      />
    </LazyLoader>
  );
};

const NameAndIssuesCell: React.FunctionComponent<{
  databaseId: string;
  schemaTable: SchemaTableList_getSchemaTables;
}> = ({ databaseId, schemaTable }) => {
  const { databaseTable } = useRoutes();
  return (
    <span>
      <Link to={databaseTable(databaseId, schemaTable.id)}>
        {schemaTable.tableName}
      </Link>
      {schemaTable.issues.map(
        (i: SchemaTableList_getSchemaTables_issues): React.ReactNode => (
          <Link
            to={databaseTable(databaseId, schemaTable.id)}
            className={`state-${i.severity}`}
            title={`${i.displayName || ""} ${i.description}`}
            key={i.id}
          >
            <i className="icon-exclamation-sign" />
          </Link>
        ),
      )}
    </span>
  );
};

export default Table;
