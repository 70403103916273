import React from "react";

/**
 * Allows using the child component as a tag on some text (e.g., an icon for an
 * external link) and prevents line break opportunities between this child and
 * adjacent inline content. This ensures the icon is not pushed onto a separate
 * line as an orphan.
 */
const TextTag: React.FunctionComponent = ({ children }) => {
  return <span className="whitespace-nowrap">{children}</span>;
};

export default TextTag;
