import React from "react";

import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "components/Button";

import {
  IndexSelectionResultType,
  findByLocator,
  isIndexType,
  isScanType,
} from "../util";

import { IndexIcon, ScanIcon, SeqScanIcon } from "../Icons";
import IndexDetails from "../IndexDetails";
import SeqScanDetails from "../SeqScanDetails";
import ScanDetails from "../ScanDetails";
import { ModalContent, ModalOverlay } from "components/Modal";

const Details: React.FunctionComponent<{
  databaseId: string;
  result: IndexSelectionResultType;
  locator: string;
  onClose: () => void;
}> = ({ databaseId, result, locator, onClose }) => {
  let title: React.ReactNode;
  let content: React.ReactNode;
  const selection = findByLocator(locator, result);
  if (!selection) {
    return null;
  } else if (selection === "seq scan") {
    title = (
      <>
        <SeqScanIcon className="mr-1" />
        Sequential Scan
      </>
    );
    content = <SeqScanDetails result={result} />;
  } else if (isScanType(selection)) {
    title = (
      <>
        <ScanIcon label={selection.label} className="mr-1" />
        Scan
      </>
    );
    content = (
      <ScanDetails databaseId={databaseId} result={result} scan={selection} />
    );
  } else if (isIndexType(selection)) {
    title = (
      <>
        <IndexIcon
          label={selection.label}
          className="mr-1"
          selected={selection.selected}
          existing={selection.existing}
        />
        Index
      </>
    );
    content = (
      <IndexDetails databaseId={databaseId} result={result} index={selection} />
    );
  }

  return (
    <Layout title={title} onClose={onClose}>
      {content}
    </Layout>
  );
};

const Layout: React.FunctionComponent<{
  title?: React.ReactNode;
  onClose: () => void;
}> = ({ title, onClose, children }) => {
  const iconClickHandler = () => {
    onClose();
  };
  return (
    <ModalOverlay className="bg-[#000000AF] z-[100]" onDismiss={onClose}>
      <ModalContent>
        <div className="flex space-between bg-[#f7fafc] text-[#606060] border-b border-solid border-[#e8e8ee] text-lg leading-9 pl-4">
          <span className="w-full inline-flex items-center overflow-hidden text-ellipsis">
            {title}
          </span>
          <Button
            className="float-right text-lg !p-3"
            bare
            onClick={iconClickHandler}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div className="m-5 mb-24">{children}</div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Details;
