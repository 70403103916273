import React from "react";

import { useQuery } from "@apollo/client";

import PageContent from "components/PageContent";
import Loading from "components/Loading";
import Panel from "components/Panel";

import QUERY from "./Query.graphql";

import {
  GetIndexAdvisorServerOverview,
  GetIndexAdvisorServerOverviewVariables,
} from "./types/GetIndexAdvisorServerOverview";
import { useCurrentServer } from "components/WithCurrentOrganization";
import { useRoutes } from "utils/routes";
import { Link } from "react-router-dom";
import { formatBytes, formatNumber } from "utils/format";
import { useFeature } from "components/OrganizationFeatures";
import UpgradeRequired from "components/UpgradeRequired";
import Grid from "components/Grid";

const IndexAdvisorServerOverview: React.FunctionComponent = () => {
  const hasIndexAdvisor = useFeature("indexAdvisor");
  if (!hasIndexAdvisor) {
    return <UpgradeRequired feature="Index Advisor" />;
  }

  return <IndexAdvisorServerOverviewContent />;
};

const IndexAdvisorServerOverviewContent: React.FunctionComponent = () => {
  const hasIndexAdvisorV3issues = useFeature("indexAdvisorV3Issues");
  const checkGroupsAndNames = ["schema/index_unused"];
  if (hasIndexAdvisorV3issues) {
    checkGroupsAndNames.push("index_advisor/indexing_engine");
  } else {
    checkGroupsAndNames.push("index_advisor/missing_index");
  }

  const { databaseIndexAdvisor } = useRoutes();
  const { humanId: serverId } = useCurrentServer();
  const { data, loading, error } = useQuery<
    GetIndexAdvisorServerOverview,
    GetIndexAdvisorServerOverviewVariables
  >(QUERY, {
    variables: {
      serverId,
      checkGroupsAndNames,
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const databases = data.getDatabases.map((db) => {
    const { indexWriteOverhead, ...rest } = db;
    return {
      ...rest,
      indexWriteOverhead: indexWriteOverhead.totalWriteOverhead,
    };
  });

  return (
    <PageContent
      title="Index Advisor"
      pageCategory="index-advisor"
      pageName="server-index"
    >
      <Panel title="Databases">
        <Grid
          className="grid-cols-[minmax(250px,1fr)_minmax(10%,150px)_minmax(15%,200px)_repeat(2,minmax(10%,150px))]"
          data={databases}
          striped
          columns={[
            {
              field: "datname",
              header: "Name",
              renderer: function DatabaseCell({ rowData, fieldData }) {
                return (
                  <Link to={databaseIndexAdvisor(rowData.id)}>{fieldData}</Link>
                );
              },
            },
            {
              field: "issueCount",
              header: "Insights",
              style: "number",
              renderer: function IssueCountCell({ rowData, fieldData }) {
                return (
                  <Link to={databaseIndexAdvisor(rowData.id)}>{fieldData}</Link>
                );
              },
            },
            {
              field: "indexWriteOverhead",
              header: "Index Write Overhead",
              style: "number",
              nullValue: "n/a",
              renderer: function IndexWriteOverheadCell({ fieldData }) {
                return formatNumber(fieldData, 2);
              },
            },
            {
              field: "tableCount",
              header: "Tables",
              style: "number",
              renderer: function TableCountCell({ fieldData }) {
                const hasFullCount = fieldData <= 100;
                return hasFullCount ? fieldData : "100+";
              },
            },
            {
              field: "totalIndexSize",
              header: "Index Size",
              style: "number",
              renderer: function TotalIndexSizeCell({ rowData, fieldData }) {
                const hasFullSize = rowData.tableCount <= 100;
                const sizeStr = formatBytes(fieldData);
                return hasFullSize ? sizeStr : sizeStr + "+";
              },
            },
          ]}
        />
      </Panel>
    </PageContent>
  );
};

export default IndexAdvisorServerOverview;
