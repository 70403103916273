import React, { useState } from "react";

import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "components/Button";

import { ModalContent, ModalOverlay } from "components/Modal";
import { GetIndexAdvisorConfigForDatabase_getIndexAdvisorConfigForDatabase as IndexAdvisorConfig } from "../types/GetIndexAdvisorConfigForDatabase";
import Grid from "components/Grid";
import Panel from "components/Panel";
import { useRoutes } from "utils/routes";
import { makeFilter } from "utils/filter";
import FilterSearch from "components/FilterSearch";
import { Link } from "react-router-dom";
import { formatNumberWithThreshold, formatTimestampShort } from "utils/format";
import moment from "moment";

const Details: React.FunctionComponent<{
  databaseId: string;
  data: IndexAdvisorConfig[];
  settingName: string;
  onClose: () => void;
}> = ({ databaseId, data, settingName, onClose }) => {
  const title = `Tables using ${settingName} configuration`;
  const tablesData = data.filter((val) => val.settingsName === settingName);

  return (
    <Layout title={title} onClose={onClose}>
      <TableList databaseId={databaseId} tablesData={tablesData} />
    </Layout>
  );
};

const TableList: React.FunctionComponent<{
  databaseId: string;
  tablesData: IndexAdvisorConfig[];
}> = ({ databaseId, tablesData }) => {
  const { databaseTableIndexSelection } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = tablesData.filter(makeFilter(searchTerm, "tableName"));

  return (
    <Panel
      title={`Tables (${filteredData.length})`}
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <Grid
        className="grid-cols-[1fr_120px_220px]"
        data={filteredData}
        defaultSortBy="lastRunAt"
        columns={[
          {
            field: "tableName",
            header: "Table",
            renderer: function TableCell({ fieldData, rowData }) {
              return (
                <Link
                  to={databaseTableIndexSelection(
                    databaseId,
                    rowData.id.toString(),
                  )}
                >
                  {fieldData}
                </Link>
              );
            },
          },
          {
            field: "scansPerMin",
            header: "Scans/Min",
            renderer: ({ fieldData }) =>
              formatNumberWithThreshold(fieldData, 1),
            style: "number",
          },
          {
            field: "lastRunAt",
            header: "Last Run",
            renderer: function LastRunCell({ fieldData }) {
              return formatTimestampShort(moment.unix(fieldData));
            },
            nullValue: "n/a",
            tip: (
              <>
                The last time the Indexing Engine processed this table to find
                insights. Shows n/a if the Indexing Engine run was skipped due
                to the Ignored configuration or if there were no scans on the
                table in the last 7 days. Newly created tables that the Indexing
                Engine hasn't processed yet also show as n/a.
              </>
            ),
            defaultSortOrder: "desc",
          },
        ]}
        pageSize={10}
      />
    </Panel>
  );
};

const Layout: React.FunctionComponent<{
  title?: React.ReactNode;
  onClose: () => void;
}> = ({ title, onClose, children }) => {
  const iconClickHandler = () => {
    onClose();
  };
  return (
    <ModalOverlay className="bg-[#000000AF] z-[100]" onDismiss={onClose}>
      <ModalContent className="md:ml-[500px]">
        <div className="flex space-between bg-[#f7fafc] text-[#606060] border-b border-solid border-[#e8e8ee] text-lg leading-9 pl-4">
          <span className="w-full inline-flex items-center overflow-hidden text-ellipsis">
            {title}
          </span>
          <Button
            className="float-right text-lg !p-3"
            bare
            onClick={iconClickHandler}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div className="m-5 mb-24">{children}</div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Details;
