import React from "react";

import classNames from "classnames";

import { Link } from "react-router-dom";

import { formatNumber } from "utils/format";
import { useRoutes } from "utils/routes";

import IssueDescription from "components/IssueDescription";

import styles from "./style.module.scss";
import Tip from "components/Tip";

export type IssueType = {
  id: string;
  databaseId: string;
  checkGroupAndName: string;
  detailsJson: string;
  descriptionTemplate: string;
  descriptionReferences: {
    param: string;
    name: string;
  }[];
};

type Props = {
  issue: IssueType;
  showTable?: boolean;
};

const IndexAdvisorIssueSummaryCard: React.FunctionComponent<Props> = ({
  issue,
  showTable,
}) => {
  switch (issue.checkGroupAndName) {
    case "index_advisor/missing_index":
      return <MissingIndexSummaryCard issue={issue} showTable={showTable} />;
    case "schema/index_unused":
      return <UnusedIndexSummaryCard issue={issue} showTable={showTable} />;
    case "Inefficient Index":
      return (
        <InefficientIndexSummaryCard issue={issue} showTable={showTable} />
      );
    default:
      return null;
  }
};

const MissingIndexSummaryCard: React.FunctionComponent<Props> = ({
  issue,
  showTable,
}) => {
  const { databaseIssue } = useRoutes();
  const issueUrl = databaseIssue(
    issue.databaseId,
    issue.id,
    issue.checkGroupAndName,
  );

  const issueDetails = JSON.parse(issue.detailsJson);
  const scanCount = issueDetails.scan_count;
  const queryCount = issueDetails.query_count;
  const queryImprovementFactor = formatNumber(
    parseFloat(issueDetails.aggregate_improvement),
    2,
  );
  const table =
    showTable &&
    issue.descriptionReferences.find((t) => t.param === "table")?.name;
  return (
    <>
      <div className={styles.listCell}>
        <div className={styles.panelHeading}>Missing Index</div>
        <div>
          We found an insight
          {table && (
            <>
              {" "}
              on <strong>{table}</strong>
            </>
          )}{" "}
          that affects{" "}
          <strong>
            {scanCount} scans across {queryCount} queries
          </strong>
          .
        </div>
      </div>
      <div className={styles.listCell}>
        <div
          className={classNames(styles.panelHeading, styles.improvementHeading)}
        >
          {queryImprovementFactor}× faster queries{" "}
          <Tip content="Average expected performance improvement across all queries, based on Postgres cost model" />
          <br />
        </div>
        <div>
          Adding an index can help speed up these queries by reading less data
          from disk.
        </div>
        <div className="mt-2">
          <Link to={issueUrl}>View insight</Link>
        </div>
      </div>
    </>
  );
};

const UnusedIndexSummaryCard: React.FunctionComponent<Props> = ({ issue }) => {
  const { databaseIssue } = useRoutes();
  const issueUrl = databaseIssue(
    issue.databaseId,
    issue.id,
    issue.checkGroupAndName,
  );
  const issueDetails = JSON.parse(issue.detailsJson);
  const writeOverhead =
    issueDetails.write_overhead == null
      ? "?"
      : formatNumber(issueDetails.write_overhead, 2);

  return (
    <>
      <div className={styles.listCell}>
        <div className={styles.panelHeading}>Unused Index</div>
        <IssueDescription issue={issue} />
      </div>
      <div className={styles.listCell}>
        <div
          className={classNames(styles.panelHeading, styles.improvementHeading)}
        >
          {writeOverhead} less index write overhead{" "}
          <Tip content="Expected reduction in I/O on writes to the table, based on no longer having to maintain this index. Calculated in bytes written to update indexes for each byte written to the table." />
        </div>
        <div>
          This index must be updated for inserts, updates, and vacuums on the
          table, and it is not being used by any queries.
        </div>
        <div className="mt-2">
          <Link to={issueUrl}>View insight</Link>
        </div>
      </div>
    </>
  );
};

const InefficientIndexSummaryCard: React.FunctionComponent<Props> = ({
  issue,
  showTable,
}) => {
  const { databaseIssue } = useRoutes();
  const issueUrl = databaseIssue(
    issue.databaseId,
    issue.id,
    issue.checkGroupAndName,
  );

  const issueDetails = JSON.parse(issue.detailsJson);
  const scanCount = issueDetails.scan_count;
  const queryCount = issueDetails.query_count;
  const queryImprovementFactor = formatNumber(
    parseFloat(issueDetails.aggregate_improvement),
    2,
  );
  const table =
    showTable &&
    issue.descriptionReferences.find((t) => t.param === "table")?.name;

  const writeOverheadImprovementFactor = "?";
  return (
    <>
      <div className={styles.listCell}>
        <div className={styles.panelHeading}>Inefficient Index</div>
        <div>
          We found an insight
          {table && (
            <>
              {" "}
              on <strong>{table}</strong>
            </>
          )}{" "}
          that affects {scanCount} scans across {queryCount} queries.
        </div>
      </div>
      <div className={styles.listCell}>
        <div
          className={classNames(styles.panelHeading, styles.improvementHeading)}
        >
          {queryImprovementFactor}× faster queries
          <br />
          {writeOverheadImprovementFactor}× less index write overhead
        </div>
        <div>
          Adding a more appropriate index can help speed up these queries by
          reading less data from disk.
        </div>
        <div className="mt-2">
          <Link to={issueUrl}>View insight</Link>
        </div>
      </div>
    </>
  );
};

export default IndexAdvisorIssueSummaryCard;
