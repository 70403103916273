import React, { FormEvent, MouseEvent, useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { FormState, Inputs } from "react-use-form-state";
import { useMutation } from "@apollo/client";
import moment, { Moment } from "moment-timezone";
import classNames from "classnames";

import { useRoutes } from "utils/routes";
import { useUnmounted } from "utils/hooks";
import { formatDateShort } from "utils/format";
import { useCurrentOrganization } from "components/WithCurrentOrganization";

import {
  CreateSupportTicket,
  CreateSupportTicketVariables,
} from "./types/CreateSupportTicket";
import MUTATION from "./Mutation.graphql";

import styles from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faTimes,
  faUndo,
} from "@fortawesome/pro-solid-svg-icons";
import Button from "components/Button";
import { ModalContent, ModalOverlay } from "components/Modal";

export type TicketCategory =
  | "setup_issue"
  | "data_metrics_problem"
  | "index_advisor"
  | "product_question"
  | "feature_request"
  | "billing_question"
  | "other";

export type SupportFormFields = {
  ticketSubject: string;
  ticketBody: string;
  ticketCategory: string;
  grantAccessToAccount: boolean;
};

type Props = {
  category: TicketCategory | null;
  setCategory: (newCategory: TicketCategory | null) => void;
  formState: FormState<SupportFormFields>;
  formInputs: Inputs<SupportFormFields>;
  serverId?: string;
  collectorVersion?: string | null;
  systemType?: string | null;
  monitoringUser?: string | null;
  configFromEnv?: boolean | null;
  onClose: () => void;
};

const HelpOverlay: React.FunctionComponent<Props> = ({
  category,
  setCategory,
  formState,
  formInputs,
  serverId,
  collectorVersion,
  systemType,
  monitoringUser,
  configFromEnv,
  onClose,
}) => {
  const [ticketId, setTicketId] = useState<string | null>(null);

  if (ticketId) {
    return (
      <Layout onClose={onClose}>
        <h4>Submitted Support Ticket (#{ticketId})</h4>
        <p>We aim to respond back to you within 1 to 2 business days.</p>
        <strong>
          <a
            href=""
            onClick={(evt: MouseEvent<HTMLAnchorElement>) => {
              evt.preventDefault();
              onClose();
            }}
          >
            ← Return to pganalyze
          </a>
        </strong>
      </Layout>
    );
  }

  return (
    <Layout onClose={onClose}>
      <ReviewDocsReminder />
      <CollectorUpgradeReminder
        serverId={serverId}
        collectorVersion={collectorVersion}
      />
      {category ? (
        <>
          <hr />
          <CoreSupportForm
            category={category}
            setCategory={setCategory}
            formState={formState}
            formInputs={formInputs}
            serverId={serverId}
            systemType={systemType}
            monitoringUser={monitoringUser}
            configFromEnv={configFromEnv}
            setTicketId={setTicketId}
          />
        </>
      ) : (
        <PickHelpCategory setCategory={setCategory} />
      )}
    </Layout>
  );
};

// See https://pganalyze.zendesk.com/admin/objects-rules/tickets/ticket-fields/7759298037137
const ticketCategories: [
  category: TicketCategory,
  title: string,
  description: string,
][] = [
  [
    "setup_issue",
    "Setup Issue",
    "Problem installing the collector, getting initial query performance metrics, or setting up Log Insights or Automated EXPLAIN for the first time on a server.",
  ],
  [
    "data_metrics_problem",
    "Data / Metrics Problem",
    "Problem with an existing server that has stopped receiving metrics, or is experiencing intermittent missing metrics.",
  ],
  [
    "index_advisor",
    "Index Advisor Problem",
    "Index Advisor error, missing recommendations, bad recommendations, or general Index Advisor feedback",
  ],
  [
    "product_question",
    "Product Question",
    "Question about any pganalyze functionality or features, or any feedback about the product.",
  ],
  [
    "feature_request",
    "Feature Request",
    "Request for a specific feature or extended functionality.",
  ],
  [
    "billing_question",
    "Billing Question",
    "Question about an invoice or pganalyze pricing.",
  ],
  [
    "other",
    "Other",
    "Any other questions not covered by the above categories.",
  ],
];

function getCategoryTitle(category: TicketCategory): string {
  const catInfo = ticketCategories.find(([cat]) => cat === category);
  if (!catInfo) {
    return "Unknown";
  }
  return catInfo[1];
}

const PickHelpCategory: React.FunctionComponent<{
  setCategory: (newCategory: TicketCategory) => void;
}> = ({ setCategory }) => {
  const handleCategoryClick = (e: MouseEvent<HTMLButtonElement>) => {
    const category = e.currentTarget.dataset.category;
    setCategory(category as TicketCategory);
  };
  return (
    <div>
      <strong>What kind of problem do you need help with?</strong>
      <ul className="list-none mt-4">
        {ticketCategories.map(([category, title, description]) => {
          return (
            <li
              key={category}
              className="group hover:bg-[#eee] border-b last:border-b-0 border-[#eee]"
            >
              <Button
                className="w-full text-left"
                onClick={handleCategoryClick}
                bare
                data-category={category}
              >
                <div className="px-5 py-3">
                  <span className="group-hover:underline block font-bold text-[#23527c]">
                    {title}
                  </span>
                  <span>{description}</span>
                </div>
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

type CoreSupportFormProps = Omit<
  Props,
  "category" | "setCategory" | "collectorVersion" | "onClose"
> & {
  category: TicketCategory;
  setTicketId: (ticketId: string) => void;
  setCategory: (newValue: null) => void;
};

const CoreSupportForm: React.FunctionComponent<CoreSupportFormProps> = ({
  category,
  setCategory,
  formState,
  formInputs,
  serverId,
  systemType,
  monitoringUser,
  configFromEnv,
  setTicketId,
}) => {
  const { label, text, textarea, checkbox } = formInputs;

  const [
    createSupportTicket,
    {
      data,
      called: ticketSubmitted,
      loading: ticketCreating,
      error: ticketError,
    },
  ] = useMutation<CreateSupportTicket, CreateSupportTicketVariables>(MUTATION);

  const ticketCreated = ticketSubmitted && !ticketCreating && !ticketError;
  useEffect(() => {
    if (ticketCreated && data) {
      setTicketId(data.createSupportTicket.ticketId);
      formState.clear();
    }
  }, [formState, ticketCreated, data, setTicketId]);

  const organization = useCurrentOrganization(true);

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    const { ticketSubject, ticketBody, grantAccessToAccount } =
      formState.values;
    const referencedOrganizationSlug = organization?.slug ?? null;
    const referencedServerId = serverId ?? null;
    const referencedUrl = window?.location.href ?? null;
    createSupportTicket({
      variables: {
        ticketSubject,
        ticketBody,
        ticketCategory: category,
        grantAccessToAccount,
        referencedOrganizationSlug,
        referencedServerId,
        referencedUrl,
      },
    });
  };

  return (
    <>
      {organization && (
        <p>
          You are opening a support ticket for the organization{" "}
          <strong>{organization.name}</strong>.
        </p>
      )}
      <p>
        <strong className="text-[#555]">Category</strong>
        <div>
          {getCategoryTitle(category)}{" "}
          <span className="text-[#23527c] ml-1">
            <Button bare onClick={() => setCategory(null)}>
              <FontAwesomeIcon icon={faUndo} /> change
            </Button>
          </span>
        </div>
      </p>
      <ExtraInfoReminder
        category={category}
        systemType={systemType}
        configFromEnv={configFromEnv}
        monitoringUser={monitoringUser}
      />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label {...label("ticketSubject")}>Subject</label>
          <input
            {...text("ticketSubject")}
            className="form-control"
            required
            autoFocus
          />
        </div>
        <div className="form-group">
          <label {...label("ticketBody")}>Description</label>
          <textarea
            {...textarea("ticketBody")}
            className="form-control"
            rows={8}
            required
          />
        </div>
        <div>
          <strong>Want to attach a file or screenshot?</strong>
          <p>
            You will receive a confirmation e-mail after you submit your ticket.
            You can then reply with an attachment.
          </p>
        </div>
        <div className={classNames("form-group", styles.grantAccessFormGroup)}>
          <strong>Can the pganalyze team access your account?</strong>
          <label {...label("grantAccessToAccount")}>
            <input {...checkbox("grantAccessToAccount")} /> Yes, grant access to
            my pganalyze account for debugging
          </label>
        </div>
        <button
          disabled={ticketSubmitted}
          type="submit"
          className="btn btn-success"
        >
          Submit Ticket
        </button>
      </form>
    </>
  );
};

const Layout: React.FunctionComponent<{
  onClose: () => void;
}> = ({ onClose, children }) => {
  return (
    <ModalOverlay onDismiss={onClose}>
      <ModalContent>
        <h2 className="flex justify-between bg-[#302855] m-0 mb-4 pt-2 pr-2 pb-[7px] pl-5 leading-[38px] text-white font-normal text-lg overflow-hidden">
          Create Support Ticket
          <Button
            bare
            className="focus:text-white hover:text-white"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </h2>
        <div className="m-5">{children}</div>
      </ModalContent>
    </ModalOverlay>
  );
};

const ReviewDocsReminder: React.FunctionComponent = () => {
  return (
    <div>
      <strong>Have you reviewed the pganalyze docs?</strong>
      <p>
        Many common issues can be solved using the{" "}
        <a href="https://pganalyze.com/docs">pganalyze documentation</a>.
      </p>
    </div>
  );
};

const COLLECTOR_CHANGELOG_URL =
  "https://github.com/pganalyze/collector/blob/main/CHANGELOG.md#changelog";

const CollectorUpgradeReminder: React.FunctionComponent<{
  serverId: string | undefined;
  collectorVersion?: string | null;
}> = ({ serverId, collectorVersion }) => {
  const { serverEdit } = useRoutes();
  const latestCollectorInfo = useLatestCollectorInfo();
  if (collectorVersion == null || latestCollectorInfo == null) {
    const latestInfoBlurb = latestCollectorInfo && (
      <>
        The latest version is {latestCollectorInfo.tagName}
        {latestCollectorInfo.publishedAt && (
          <> published on {formatDateShort(latestCollectorInfo.publishedAt)}</>
        )}
        .{" "}
      </>
    );
    // If a specific server is selected, we can link to it; otherwise describe the page
    const serverSettingsText = serverId ? (
      <Link to={serverEdit(serverId)}>Server Settings</Link>
    ) : (
      '"Server Settings"'
    );
    return (
      <div>
        <strong>Are you running the latest collector version?</strong>
        <p>
          {latestInfoBlurb}
          For more details, see the{" "}
          <a rel="noopener" target="_blank" href={COLLECTOR_CHANGELOG_URL}>
            pganalyze collector CHANGELOG
          </a>
          . You can check the version reporting for your server under{" "}
          {serverSettingsText} in the sidebar.
        </p>
      </div>
    );
  }
  // the tag itself starts with a 'v' and then follows with the version number
  const latestTaggedVersion = latestCollectorInfo.tagName.substring(1);
  const isRunningLatest = latestTaggedVersion === collectorVersion;
  if (isRunningLatest) {
    return (
      <div>
        <p>
          <FontAwesomeIcon icon={faCheckCircle} className="text-[#93bf3b]" />{" "}
          You are running the latest collector version ({collectorVersion}) for
          this server.
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-[#ffa700]"
          />{" "}
          Your collector version for this server is {collectorVersion}, but the
          latest is {latestTaggedVersion}. Please check the{" "}
          <a rel="noopener" target="_blank" href={COLLECTOR_CHANGELOG_URL}>
            CHANGELOG
          </a>{" "}
          to see if upgrading could resolve your issue.
        </p>
      </div>
    );
  }
};

const LATEST_RELEASE_URL =
  "https://api.github.com/repos/pganalyze/collector/releases/latest";

type CollectorReleaseInfo = {
  publishedAt: Moment | undefined;
  tagName: string;
};

function useLatestCollectorInfo(): CollectorReleaseInfo | null {
  const [info, setInfo] = useState<CollectorReleaseInfo | null>(null);
  const unmounted = useUnmounted();
  useEffect(() => {
    fetch(LATEST_RELEASE_URL)
      .then((response) => response.json())
      .then((result) => {
        if (unmounted.current || !result.tag_name) {
          return;
        }
        setInfo({
          publishedAt: result.published_at
            ? moment(result.published_at)
            : undefined,
          tagName: result.tag_name,
        });
      })
      .catch(() => {
        // this is just some additional info for users filing an issue, so
        // we can ignore failures if we fail to retrieve it
      });
  }, [unmounted]);
  return info;
}

const ExtraInfoReminder: React.FunctionComponent<{
  category: TicketCategory;
  systemType: string | null | undefined;
  configFromEnv: boolean | null | undefined;
  monitoringUser: string | null | undefined;
}> = ({ category, systemType, configFromEnv, monitoringUser }) => {
  configFromEnv ??= false;
  monitoringUser ??= "pganalyze";
  const needsDiagnosticInfo = !(
    [
      "product_question",
      "billing_question",
      "feature_request",
    ] as TicketCategory[]
  ).includes(category);
  if (!needsDiagnosticInfo) {
    return null;
  }
  const isHeroku = systemType === "heroku";
  const isMissingData = category === "data_metrics_problem";
  const isDocker = configFromEnv && !isHeroku;
  const testCmd = isHeroku
    ? "heroku run --app [your-collector-app] collector --test --verbose"
    : isDocker
    ? "sudo docker run --env-file /path/to/your/docker-env [your-collector-image-id] test"
    : "pganalyze-collector --test --verbose";
  const statusCmd = isHeroku
    ? "heroku ps --app [your-collector-app]"
    : isDocker
    ? "sudo docker ps --all --filter=id=[your-collector-container-id]"
    : "systemctl status pganalyze-collector";
  const logsCmd = isHeroku
    ? "heroku logs -n 100 --app [your-collector-app]"
    : isDocker
    ? "sudo docker logs --timestamps [your-collector-container-id]"
    : "journalctl -u pganalyze-collector -n 100";
  const configCmd = isHeroku
    ? "heroku config --app [your-collector-app]"
    : isDocker
    ? "sudo docker inspect [your-collector-container-id] --format='{{.Config.Env}}'"
    : "cat /etc/pganalyze-collector.conf";
  const checkHasPgMonitor = `SELECT pg_has_role('${monitoringUser}', 'pg_monitor', 'member')`;

  return (
    <div>
      <strong className="text-[#555]">
        To help track down problems quickly, please include all relevant
        information:
      </strong>
      {isDocker && (
        <p>
          You can check your collector image id with{" "}
          <code>
            sudo docker images --filter=reference='quay.io/pganalyze/*'
          </code>
          and your collector container id with{" "}
          <code>
            sudo docker ps --latest --filter=ancestor=[your-collector-image-id]
          </code>
          .
        </p>
      )}
      <ul className="pl-7">
        {isMissingData && (
          <li>
            date range when data was missing (if limited to specific window)
          </li>
        )}
        <li>
          output of <code>{configCmd}</code> (please redact anything sensitive,
          such as passwords and API keys)
        </li>
        {isHeroku && (
          <>
            <li>
              output of <code>heroku addons --app [your-collector-app]</code>
            </li>
            <li>
              output of <code>heroku addons --app [your-app]</code>
            </li>
            <li>
              output of <code>heroku drains --app [your-app]</code>
            </li>
          </>
        )}
        <li>
          output of <code>{testCmd}</code>
        </li>
        <li>
          output of <code>{statusCmd}</code>
        </li>
        <li>
          output of <code>{logsCmd}</code>
        </li>
        <li>
          output of <code>{checkHasPgMonitor}</code> in your database
        </li>
      </ul>
    </div>
  );
};

export default HelpOverlay;
