import React, { useState } from "react";

import ExplainDetails from "components/ExplainDetails";

import ExpandableSQL from "components/ExpandableSQL";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";

import ExplainComparison from "./ExplainComparison";
import ExplainContext from "./ExplainContext";
import ExplainInsights from "./ExplainInsights";
import ExplainStatsAndOptions from "./ExplainStatsAndOptions";
import ExplainViewSource from "./ExplainViewSource";

import styles from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/pro-regular-svg-icons";
import { faCogs } from "@fortawesome/pro-solid-svg-icons";

export type ExplainPlanType = {
  id: string;
  seenAt: number;
  querySample:
    | {
        occurredAt: number;
        runtimeMs: number;
        queryText: string;
        parameters?: string[];
      }
    | null
    | undefined;
  query: {
    id: string;
  };
  format: string;
  fingerprint: string;
  outputText: string;
  annotatedJson: string;
  totalSharedBlksRead: number;
  totalBlkReadTime: number;
};

type Props = {
  explain: ExplainPlanType;
  databaseId: string;
  blockSize: number;
};

const ExplainPanel: React.FunctionComponent<Props> = ({
  explain,
  databaseId,
  blockSize,
}) => {
  let panelContent;

  const [showPlanComparison, setShowPlanComparison] = useState(false);
  const [showSource, setShowSource] = useState(false);

  let queryContent;
  if (explain.querySample) {
    const parameters = explain.querySample.parameters;
    queryContent = (
      <>
        <PanelSection>
          <ExpandableSQL sql={explain.querySample.queryText} />
        </PanelSection>
        {parameters && parameters.length > 0 && (
          <PanelSection>
            <pre className={styles.noborder}>
              {parameters
                .map(
                  (p: string, index: number): React.ReactNode =>
                    `$${index + 1} = '${p}'`,
                )
                .join(", ")}
            </pre>
          </PanelSection>
        )}
      </>
    );
  }

  const plan = JSON.parse(explain.annotatedJson);
  if (showPlanComparison) {
    const handleHideComparison = () => {
      setShowPlanComparison(false);
    };
    panelContent = (
      <PanelSection>
        <ExplainComparison
          databaseId={databaseId}
          blockSize={blockSize}
          plan={plan}
          explain={explain}
          onHide={handleHideComparison}
        />
      </PanelSection>
    );
  } else if (showSource) {
    panelContent = (
      <PanelSection>
        <ExplainViewSource
          annotatedPlan={plan}
          textPlan={explain.outputText}
          onHide={() => setShowSource(false)}
        />
      </PanelSection>
    );
  } else {
    panelContent = (
      <>
        <PanelSection>
          <div className={styles.statsAndInsights}>
            <div>
              <ExplainStatsAndOptions
                ioBlocks={explain.totalSharedBlksRead}
                ioMs={explain.totalBlkReadTime}
                plan={plan.plan}
                runtime={explain.querySample.runtimeMs}
                blockSize={blockSize}
              />
              <div className={styles.summaryLinks}>
                <a
                  href=""
                  onClick={(evt) => {
                    evt.preventDefault();
                    setShowPlanComparison(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTable} /> Compare Query Plans
                </a>
                <a
                  href=""
                  onClick={(evt) => {
                    evt.preventDefault();
                    setShowSource(true);
                  }}
                >
                  <FontAwesomeIcon icon={faCogs} /> View EXPLAIN Source
                </a>
              </div>
            </div>
            <ExplainInsights annotations={plan.annotations} />
          </div>
        </PanelSection>
        {queryContent}
      </>
    );
  }

  return (
    <>
      <Panel
        title="EXPLAIN Plan"
        key={explain.id}
        secondaryTitle={<ExplainContext explain={explain} />}
      >
        {panelContent}
      </Panel>
      <ExplainDetails
        plan={plan}
        databaseId={databaseId}
        blockSize={blockSize}
      />
    </>
  );
};

export default ExplainPanel;
