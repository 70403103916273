import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";

import { formatTimestampShort } from "utils/format";
import {
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";

import IndexCheck from "components/IndexCheck";
import Loading from "components/Loading";
import LogLines from "components/LogLines";
import PageContent from "components/PageContent";
import PageIssueList from "components/PageIssueList";
import Panel from "components/Panel";
import QueryTagOverview from "components/QueryTagOverview";
import QuerySamples from "components/QuerySamples";

import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import UpgradeRequired from "components/UpgradeRequired";
import { formatEstimatedCount } from "utils/format";

import Graph from "./Graph";
import QUERY from "./Query.graphql";
import IAV2_QUERY from "./Query.indexissues.graphql";
import styles from "./style.module.scss";

import {
  QueryDetails as QueryDetailsType,
  QueryDetailsVariables,
  QueryDetails_getQueryDetails_postgresRole,
} from "./types/QueryDetails";
import { useFeature } from "components/OrganizationFeatures";
import DateRangeBar from "components/DateRangeBar";
import { useDateRange } from "components/WithDateRange";
import moment, { Moment } from "moment-timezone";
import { useTimeout } from "utils/hooks";
import { useRoutes } from "utils/routes";
import QueryExplainsList from "components/QueryExplainList";
import QueryExplain from "components/QueryExplain";
import PanelSection from "components/PanelSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";
import ExpandableSQL from "components/ExpandableSQL";
import {
  IndexAdvisorResult,
  QueryAnalysisResult,
  transformQueryAnalysis,
} from "../IndexAdvisor/util";
import QueryDetailsIndexAdvisor from "./QueryDetailsIndexAdvisor";
import {
  QueryDetailIndexAdvisorIssues,
  QueryDetailIndexAdvisorIssuesVariables,
  QueryDetailIndexAdvisorIssues_getIssues as IssueType,
} from "./types/QueryDetailIndexAdvisorIssues";
import ContactSupportLink from "components/ContactSupportLink";
import { ExchangeIcon, InfoIcon } from "components/Icons";
import { isMissingQueryText } from "./util";
import QueryDetailsHeader from "./QueryDetailsHeader";

type Props = {
  tab: string;
};

const QueryDetails: React.FunctionComponent<Props> = ({ tab }) => {
  const { databaseId, queryId, explainId } = useParams();
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const hasLogs = useFeature("logs");
  const hasIndexCheck = useFeature("indexCheck");
  const hasIndexAdvisor = useFeature("indexAdvisor");
  const hasIndexAdvisorV3issues = useFeature("indexAdvisorV3Issues");
  const { organizationSubscription } = useRoutes();

  const {
    data,
    loading: detailsLoading,
    error: detailsError,
  } = useQuery<QueryDetailsType, QueryDetailsVariables>(QUERY, {
    variables: {
      databaseId,
      queryId,
    },
  });
  const {
    data: indexAdvisorData,
    loading: indexAdvisorLoading,
    error: indexAdvisorError,
  } = useQuery<
    QueryDetailIndexAdvisorIssues,
    QueryDetailIndexAdvisorIssuesVariables
  >(IAV2_QUERY, {
    variables: {
      databaseId,
      queryId,
    },
    skip: !hasIndexAdvisor,
  });
  const loading = detailsLoading || indexAdvisorLoading;
  const error = detailsError || indexAdvisorError;
  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const {
    id,
    fingerprint,
    normalizedQuery,
    normalizedQueryScanTokens,
    truncatedQuery,
    postgresRole,
    recentQuerySamplesCount,
    recentQueryExplainsCount,
    recentQueryTagsetCount,
    recentLogLinesCount,
  } = data.getQueryDetails;
  const queryAnalysis: QueryAnalysisResult = JSON.parse(
    data.getQueryDetails.queryAnalysis,
  );
  const serverId = data.getServerDetails.humanId;
  const organizationSlug = data.getServerDetails.organization.slug;
  const trackIoTiming = data.trackIoTimingSetting?.value === "on";
  const blockSize = data.getServerDetails.blockSize;
  const { indexAdvisorTrialEndsAt } = data.getServerDetails.organization;
  const tags = data.getQueryTagSummaryForQuery;
  const queryTruncated = normalizedQuery === "<truncated query>";

  const indexAdvisorResult = transformQueryAnalysis(queryAnalysis);
  const indexAdvisorRelevantIssues = indexAdvisorData?.getIssues.filter(
    (issue) => {
      return (
        issue.checkGroupAndName !==
        (hasIndexAdvisorV3issues
          ? "index_advisor/missing_index"
          : "index_advisor/indexing_engine")
      );
    },
  );

  const indexRecommendationIcon = getIndexAdvisorRecommendationIcon(
    hasIndexAdvisor,
    indexAdvisorResult,
    indexAdvisorRelevantIssues,
  );

  return (
    <PageContent
      windowTitle={`Query #${id}`}
      pageControls={<DateRangeBar />}
      featureInfo={
        <QueryDetailsHeader
          serverId={serverId}
          databaseId={databaseId}
          queryId={id}
          fingerprint={fingerprint}
          truncatedQuery={truncatedQuery}
          role={postgresRole}
          tags={tags}
        />
      }
      featureNav={
        <QueryDetailsNav
          databaseId={databaseId}
          queryId={queryId}
          indexRecommendationIcon={indexRecommendationIcon}
          recentQuerySamplesCount={recentQuerySamplesCount}
          recentQueryExplainsCount={recentQueryExplainsCount}
          recentQueryTagsetCount={recentQueryTagsetCount}
          recentLogLinesCount={recentLogLinesCount}
        />
      }
      pageCategory="queries"
      pageName="show"
      pageTab={tab}
    >
      <PageIssueList serverId={serverId} referentId={id} referentType="Query" />
      {tab == "indexadvisor" && indexAdvisorTrialEndsAt && (
        <Panel title="Index Advisor Trial Enabled">
          <PanelSection>
            <InfoIcon /> The trial for the new pganalyze Index Advisor is active
            on your account until{" "}
            {moment(indexAdvisorTrialEndsAt * 1000).format("MMMM DD, YYYY")}.{" "}
            <strong>
              <a
                href={organizationSubscription(organizationSlug)}
                target="_blank"
              >
                Upgrade to the new pricing plans
              </a>
            </strong>{" "}
            to continue using the Index Advisor.
          </PanelSection>
        </Panel>
      )}
      {normalizedQuery == "<pganalyze-collector>" ? (
        <CollectorQueryInfoPanel />
      ) : isMissingQueryText(normalizedQuery) ? (
        <MissingQueryTextWarningPanel />
      ) : (
        tab != "explains" &&
        tab != "samples" && (
          <Panel title="SQL Statement">
            <PanelSection>
              {queryTruncated ? (
                <QueryTruncated serverId={serverId} />
              ) : (
                <ExpandableSQL
                  sql={normalizedQuery}
                  databaseId={databaseId}
                  scanTokens={normalizedQueryScanTokens}
                />
              )}
            </PanelSection>
          </Panel>
        )
      )}
      {tab == "overview" && (
        <Graph
          databaseId={databaseId}
          queryId={id}
          trackIoTiming={trackIoTiming}
        />
      )}
      {tab == "indexcheck" &&
        (hasIndexCheck ? (
          <IndexCheck databaseId={databaseId} queryId={id} />
        ) : (
          <UpgradeRequired feature="Index Check" panelOnly />
        ))}
      {tab == "indexadvisor" && (
        <IndexAdvisorTab
          databaseId={databaseId}
          queryId={queryId}
          error={indexAdvisorResult.error}
          hasIndexAdvisor={hasIndexAdvisor}
          indexAdvisorIssues={indexAdvisorRelevantIssues}
        />
      )}
      {tab == "samples" && !hasLogs && (
        <UpgradeRequired
          feature="Log Insights"
          additionalText={
            <span>
              <strong>Error:</strong> Query Sample collection requires Log
              Insights.
            </span>
          }
          panelOnly
        />
      )}
      {tab == "samples" && hasLogs && (
        <QuerySamples
          serverId={serverId}
          databaseId={databaseId}
          queryId={id}
        />
      )}
      {tab == "explains" && !hasLogs && (
        <UpgradeRequired
          feature="Log Insights"
          additionalText={
            <span>
              <strong>Error:</strong> EXPLAIN plan collection requires Log
              Insights.
            </span>
          }
          panelOnly
        />
      )}
      {tab == "explains" &&
        hasLogs &&
        (explainId ? (
          <QueryExplain
            databaseId={databaseId}
            explainId={explainId}
            blockSize={blockSize}
          />
        ) : (
          <QueryExplainsList
            queryId={queryId}
            databaseId={databaseId}
            blockSize={blockSize}
          />
        ))}
      {tab == "tags" && !hasLogs && (
        <UpgradeRequired
          feature="Log Insights"
          additionalText={
            <span>
              <strong>Error:</strong> Query Tags require Log Insights.
            </span>
          }
          panelOnly
        />
      )}
      {tab == "tags" && hasLogs && (
        <QueryTagOverview
          organizationSlug={organizationSlug}
          databaseId={databaseId}
          queryId={id}
        />
      )}
      {tab == "logs" && !hasLogs && (
        <UpgradeRequired feature="Log Insights" panelOnly />
      )}
      {tab == "logs" && hasLogs && (
        <QueryLogsPanel
          {...{
            recentLogLinesCount,
            databaseId,
            serverId,
            fingerprint,
            postgresRole,
            startTs: newStartTs,
            endTs: newEndTs,
          }}
        />
      )}
    </PageContent>
  );
};

const IndexAdvisorTab: React.FunctionComponent<{
  databaseId: string;
  queryId: string;
  error?: string;
  hasIndexAdvisor: boolean;
  indexAdvisorIssues: IssueType[];
}> = ({ databaseId, queryId, error, hasIndexAdvisor, indexAdvisorIssues }) => {
  if (!hasIndexAdvisor) {
    return <UpgradeRequired feature="Index Advisor" panelOnly />;
  }
  if (error) {
    return (
      <Panel
        title={
          <span>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className={styles.indexAdvisorError}
            />{" "}
            Index Advisor Error
          </span>
        }
      >
        <PanelSection>
          <p>
            <strong>Error:</strong> Index Advisor could not analyze this query
          </p>
          <p>
            <strong>Details:</strong> {error}
          </p>
          <p>
            Please{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://pganalyze.com/docs/index-advisor"
            >
              review the documentation
            </a>{" "}
            (including current limitations) or{" "}
            <ContactSupportLink>contact support</ContactSupportLink>.
          </p>
        </PanelSection>
      </Panel>
    );
  }
  return (
    <QueryDetailsIndexAdvisor
      databaseId={databaseId}
      queryId={queryId}
      issues={indexAdvisorIssues}
    />
  );
};

const QueryLogsPanel: React.FunctionComponent<{
  databaseId: string;
  serverId: string;
  fingerprint: string;
  recentLogLinesCount: number | null;
  postgresRole: QueryDetails_getQueryDetails_postgresRole;
  startTs: Moment;
  endTs: Moment;
}> = ({
  databaseId,
  serverId,
  fingerprint,
  postgresRole,
  startTs,
  endTs,
  recentLogLinesCount,
}) => {
  const [jumped, setJumped] = useState(false);
  const [, setRecheckTime] = useState(false);
  const [, setRange] = useDateRange();
  // If we're current when we mount, check again after we expect to be stale
  const thresholdMinutes = 5;
  useTimeout(
    () => setRecheckTime(true),
    moment.duration(thresholdMinutes * 1.1, "minutes").asMilliseconds(),
    [],
  );

  const isNowish = (start: Moment, end: Moment): boolean => {
    const result =
      start.isBefore(
        moment().subtract(24, "hours").add(thresholdMinutes, "minutes"),
      ) && moment().diff(end, "minutes", true) < thresholdMinutes;
    return result;
  };

  const showJumpPrompt =
    !jumped &&
    !isNowish(startTs, endTs) &&
    recentLogLinesCount &&
    recentLogLinesCount > 0;

  const handleJump = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    evt.preventDefault();
    setRange({ from: moment().subtract(24, "hours"), to: moment() });
    setJumped(true);
  };

  return (
    <Panel title={`Log Entries (up to ${formatTimestampShort(endTs)})`}>
      {showJumpPrompt ? (
        <div className={styles.activityInfo}>
          <ExchangeIcon />
          Up to {recentLogLinesCount} log lines outside of the selected time
          range.{" "}
          <a href="" onClick={handleJump}>
            Show latest 24h
          </a>
        </div>
      ) : null}
      <LogLines
        databaseId={databaseId}
        serverId={serverId}
        queryFingerprint={fingerprint}
        postgresRoleId={postgresRole.id}
        occurredBefore={endTs.unix()}
        occurredAfter={startTs.unix()}
      />
    </Panel>
  );
};

type NavProps = {
  databaseId: string;
  queryId: string;
  recentQuerySamplesCount: number | null;
  recentQueryExplainsCount: number | null;
  recentQueryTagsetCount: number | null;
  recentLogLinesCount: number | null;
  indexRecommendationIcon: null | number | string | React.ReactElement;
};

const QueryDetailsNav: React.FunctionComponent<NavProps> = ({
  databaseId,
  queryId,
  indexRecommendationIcon,
  recentQuerySamplesCount,
  recentQueryExplainsCount,
  recentQueryTagsetCount,
  recentLogLinesCount,
}) => {
  const hasIndexAdvisor = useFeature("indexAdvisor");
  const {
    databaseQuery,
    databaseIndexCheck,
    databaseQueryIndexAdvisor,
    databaseQuerySamples,
    databaseQueryExplains,
    databaseQueryTags,
    databaseQueryLogs,
  } = useRoutes();
  return (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseQuery(databaseId, queryId)}>
        Overview
      </PageNavLink>
      {hasIndexAdvisor ? (
        <PageNavLink to={databaseQueryIndexAdvisor(databaseId, queryId)}>
          Index Advisor <small>{indexRecommendationIcon}</small>
        </PageNavLink>
      ) : (
        <PageNavLink to={databaseIndexCheck(databaseId, queryId)}>
          Index Check
        </PageNavLink>
      )}
      <PageNavLink to={databaseQuerySamples(databaseId, queryId)}>
        Query Samples{" "}
        <small>{formatEstimatedCount(recentQuerySamplesCount, 5)}</small>
      </PageNavLink>
      <PageNavLink end={false} to={databaseQueryExplains(databaseId, queryId)}>
        EXPLAIN Plans{" "}
        <small>{formatEstimatedCount(recentQueryExplainsCount, 5)}</small>
      </PageNavLink>
      <PageNavLink to={databaseQueryTags(databaseId, queryId)}>
        Query Tags{" "}
        <small>{formatEstimatedCount(recentQueryTagsetCount, 5)}</small>
      </PageNavLink>
      <PageNavLink to={databaseQueryLogs(databaseId, queryId)}>
        Log Entries{" "}
        <small>{formatEstimatedCount(recentLogLinesCount, 100)}</small>
      </PageNavLink>
    </PageSecondaryNavigation>
  );
};

const CollectorQueryInfoPanel: React.FunctionComponent = () => {
  return (
    <Panel
      title={
        <span>
          <FontAwesomeIcon icon={faInfoCircle} /> Query Info
        </span>
      }
    >
      <PanelSection>
        This is an aggregation of all queries that the pganalyze collector runs
        to monitor your database.
      </PanelSection>
    </Panel>
  );
};

const MissingQueryTextWarningPanel: React.FunctionComponent = () => {
  return (
    <Panel
      title={
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} /> Warning: could not
          capture query text
        </span>
      }
    >
      <PanelSection>
        This is an aggregation of multiple queries from pg_stat_statements that
        couldn't be matched to a query known by pganalyze because of churn from:
        <ol>
          <li>
            Queries with a variable number of bind params like{" "}
            <code>id IN ($1, $2, ...)</code> which can be fixed by using an
            array instead: <code>id = ANY($1::bigint[])</code>
          </li>
          <li>
            Utility statements including a random comment (like a request ID).
            Postgres 16 fixes this, but{" "}
            <code>pg_stat_statements.track_utility</code>
            <a
              href="https://www.postgresql.org/docs/current/pgstatstatements.html#id-1.11.7.42.9.2.3.1.3"
              title="Learn more"
              target="_blank"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>{" "}
            can be disabled on older versions.
          </li>
          <li>
            Dynamically-generated queries with varying column lists or where
            clauses, which can be mitigated by increasing{" "}
            <code>pg_stat_statements.max</code>{" "}
            <a
              href="https://www.postgresql.org/docs/current/pgstatstatements.html#id-1.11.7.42.9.2.1.1.3"
              title="Learn more"
              target="_blank"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          </li>
        </ol>
      </PanelSection>
    </Panel>
  );
};

const QueryTruncated: React.FunctionComponent<{ serverId: string }> = ({
  serverId,
}) => {
  const { serverConfigSetting } = useRoutes();
  return (
    <>
      <p>
        <InfoIcon className="mr-1.5" />
        This query is truncated.
      </p>
      <div>
        The query has been truncated as it exceeds the current size limit set by{" "}
        <Link to={serverConfigSetting(serverId, "track_activity_query_size")}>
          track_activity_query_size
        </Link>
        . If you encounter this issue frequently, consider increasing this
        limit. Be aware that increasing the limit may result in Postgres
        consuming more memory.
      </div>
    </>
  );
};

function getIndexAdvisorRecommendationIcon(
  hasIndexAdvisor: boolean,
  indexAdvisorResult: IndexAdvisorResult,
  indexAdvisorIssues: IssueType[] | undefined,
): null | number | string | React.ReactElement {
  if (!hasIndexAdvisor || "error" in indexAdvisorResult) {
    return "?";
  }

  const recommendationCount = indexAdvisorIssues.length;

  if (recommendationCount === 0) {
    return (
      <FontAwesomeIcon className={styles.indexAdvisorAOK} icon={faCheck} />
    );
  }

  return recommendationCount;
}

export default QueryDetails;
