import React from "react";

import { Link } from "react-router-dom";

import { useRoutes } from "utils/routes";

import Grid from "components/Grid";
import ImpactRenderer from "components/ImpactRenderer";

import styles from "./style.module.scss";

import {
  GetIndexAdvisorIssues_getIssues as IssueType,
  GetIndexAdvisorIssues_getSchemaTables as SchemaTableType,
} from "../types/GetIndexAdvisorIssues";

import { getTableName } from "..";
import {
  formatApproximateNumber,
  formatBytes,
  formatNumber,
} from "utils/format";
import Popover from "components/Popover";
import { makeFilter } from "utils/filter";
import pluralize from "pluralize";
import { calculateImpact } from "components/IndexAdvisor/util";

const MissingIndexList: React.FunctionComponent<{
  searchTerm: string;
  issues: IssueType[];
  tables: SchemaTableType[];
  databaseId: string;
}> = ({ searchTerm, issues, tables, databaseId }) => {
  const { databaseIssue } = useRoutes();
  const statsByTable = tables.reduce<{ [key: string]: SchemaTableType }>(
    (byTableMap, table) => {
      byTableMap[table.id] = table;
      return byTableMap;
    },
    {},
  );

  const gridData = issues
    .map((issue) => {
      const tableId = JSON.parse(issue.groupingKey).table;
      const tableName = getTableName(issue);
      const idxDef =
        issue.descriptionReferences.find(
          (ref) => ref.param === "index_definition",
        )?.name ?? "[unknown index]";
      const details = JSON.parse(issue.detailsJson);
      const improvement = details.aggregate_improvement;
      const tableSize = statsByTable[tableId]?.lastStats?.dataSizeBytes;
      const writeOverhead = details.index_write_overhead;
      const tableWritesPerMin = details.table_writes_per_min;

      return {
        id: issue.id,
        improvement,
        scans: details.scan_count,
        queries: details.query_count,
        tableName,
        idxDef,
        tableSize,
        tableWritesPerMin,
        writeOverhead,
      };
    })
    .filter(makeFilter(searchTerm, "idxDef", "tableName"));

  return (
    <Grid
      className={styles.missingIndexGrid}
      striped
      data={gridData}
      defaultSortBy="improvement"
      columns={[
        {
          header: "Impact",
          field: "improvement",
          className: "flex justify-center",
          defaultSortOrder: "desc",
          renderer: function Impact({ rowData: { id, scans }, fieldData }) {
            const roundedImprovement = formatApproximateNumber(fieldData);
            const impact = calculateImpact(fieldData);
            return (
              <Popover
                content={
                  <>
                    <div className={styles.costImprovement}>
                      <strong>{roundedImprovement}× cost improvement</strong>
                    </div>
                    <div>
                      This is a weighted average of the estimated cost
                      improvement across {pluralize("scan", scans, true)},
                      weighting by avg scans / minute
                    </div>
                  </>
                }
              >
                <Link
                  to={databaseIssue(
                    databaseId,
                    id,
                    "index_advisor/missing_index",
                  )}
                >
                  <ImpactRenderer impact={impact} />
                </Link>
              </Popover>
            );
          },
        },
        { header: "Table", field: "tableName" },
        {
          header: "Index",
          field: "idxDef",
          renderer: function IndexDef({ rowData, fieldData }) {
            return (
              <Link
                to={databaseIssue(
                  databaseId,
                  rowData.id,
                  "index_advisor/missing_index",
                )}
              >
                {fieldData}
              </Link>
            );
          },
        },
        {
          header: "Queries",
          field: "queries",
          defaultSortOrder: "desc",
          style: "number",
        },
        {
          header: "Table Size",
          field: "tableSize",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: ({ fieldData }) => formatBytes(fieldData),
          style: "number",
        },
        {
          header: "Table Writes / Min",
          field: "tableWritesPerMin",
          defaultSortOrder: "desc",
          nullValue: "n/a",
          renderer: ({ fieldData }) => formatNumber(fieldData, 3),
          style: "number",
        },
        {
          header: "Index Write Overhead",
          field: "writeOverhead",
          defaultSortOrder: "asc",
          nullValue: "n/a",
          renderer: function IndexWriteOverhead({ fieldData }) {
            return (
              <span className={styles.writeOverhead}>
                +{formatNumber(fieldData, 2)}
              </span>
            );
          },
          style: "number",
        },
      ]}
    />
  );
};

export default MissingIndexList;
