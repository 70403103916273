import React, { useState } from "react";

import { Link } from "react-router-dom";

import { formatMs, formatNumber, formatPercent } from "utils/format";
import { useRoutes } from "utils/routes";

import Panel from "components/Panel";
import SQL from "components/SQL";

import {
  IssueDetail_getIssue as IssueType,
  IssueDetail_getIssue_references_referent_Query,
} from "../types/IssueDetail";
import FilterSearch from "components/FilterSearch";
import Grid from "components/Grid";

const IndexAdvisorAffectedQueries: React.FunctionComponent<{
  issue: IssueType;
}> = ({ issue }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const issueQueries = issue.references
    .filter((ref) => ref.kind === "Query" && !!ref.referent)
    .map<IssueQuery>((ref) => {
      const details = JSON.parse(ref.detailsJson);
      return {
        id: (ref.referent as IssueDetail_getIssue_references_referent_Query).id,
        normalizedQuery: details.normalized_query,
        truncatedQuery: details.truncated_query,
        avgTime: details.avg_time,
        pctOfTotal: details.pct_of_total,
        callsPerMinute: details.calls_per_minute,
      };
    })
    .filter((q) => {
      return (
        searchTerm === "" ||
        q.normalizedQuery.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  return (
    <Panel
      title={`Affected Queries (${issueQueries.length})`}
      secondaryTitle={
        <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
      }
    >
      <AffectedQueriesTable
        databaseId={issue.databaseId}
        queries={issueQueries}
      />
    </Panel>
  );
};

type IssueQuery = {
  id: string;
  normalizedQuery: string;
  truncatedQuery: string;
  avgTime: number;
  pctOfTotal: number;
  callsPerMinute: number;
};

const AffectedQueriesTable: React.FunctionComponent<{
  databaseId: string;
  queries: IssueQuery[];
}> = ({ databaseId, queries }) => {
  const { databaseQuery } = useRoutes();
  return (
    <Grid
      className="grid-cols-[minmax(5%,80px),minmax(15%,1fr),repeat(3,minmax(15%,120px))]"
      striped
      data={queries}
      columns={[
        {
          field: "id",
          header: "Query ID",
          className: "text-right",
          renderer: function IDCell({ fieldData }) {
            return (
              <Link to={databaseQuery(databaseId, fieldData)}>
                #{fieldData}
              </Link>
            );
          },
        },
        {
          field: "truncatedQuery",
          header: "Query",
          style: "query",
          nullValue: "<query text not available>",
          renderer: function QueryCell({ fieldData }) {
            return <SQL inline nowrap sql={fieldData} />;
          },
        },
        {
          field: "avgTime",
          header: "Avg Time (ms)",
          style: "number",
          nullValue: "n/a",
          renderer: function AvgTimeCell({ fieldData }) {
            return formatMs(fieldData);
          },
        },
        {
          field: "callsPerMinute",
          header: "Calls / Min",
          style: "number",
          nullValue: "n/a",
          renderer: function CallsPerMinCell({ fieldData }) {
            return formatNumber(fieldData, 4);
          },
        },
        {
          field: "pctOfTotal",
          header: "% All Runtime",
          style: "number",
          nullValue: "n/a",
          renderer: function CallsPerMinCell({ fieldData }) {
            return formatPercent(fieldData / 100);
          },
        },
      ]}
    />
  );
};

export default IndexAdvisorAffectedQueries;
