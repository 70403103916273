import React, { useState } from "react";

import sortBy from "lodash/sortBy";

import PanelTitle from "components/PanelTitle";
import FilterSearch from "components/FilterSearch";
import Grid from "components/Grid";
import SchemaTableScanDetails from "components/SchemaTableScanDetails";

import { formatApproximateNumber, formatNumber } from "utils/format";

import styles from "./style.module.scss";

type TableScanType = {
  combinedSql: string;
  whereClause: string[];
  joinClause: string[];
  oldCost: number;
  newCost: number;
  scansPerMin: number;
  costImprovement: number;
  referencedQueries: number[];
};

const IndexAdvisorRelevantScans: React.FunctionComponent<{
  scans: TableScanType[];
  databaseId: string;
}> = ({ scans, databaseId }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredScans = scans
    .map((scan) => ({
      ...scan,
      // use cost ratio instead of absolute improvement
      costImprovement: scan.oldCost / scan.newCost,
    }))
    .filter((scan) => {
      return (
        searchTerm === "" ||
        scan.whereClause.some((w) =>
          w.toLowerCase().includes(searchTerm.toLowerCase()),
        ) ||
        scan.joinClause.some((j) =>
          j.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      );
    });

  const sortedScans = sortBy(filteredScans, (scan) => -scan.costImprovement);
  return (
    <>
      <PanelTitle
        title={`Scans (${scans.length})`}
        secondaryTitle={
          <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
        }
      />
      <ScanGrid databaseId={databaseId} scans={sortedScans} />
    </>
  );
};

const ScanGrid: React.FunctionComponent<{
  databaseId: string;
  scans: TableScanType[];
}> = ({ databaseId, scans }) => {
  return (
    <Grid
      className={styles.scanGrid}
      data={scans}
      columns={[
        {
          field: "combinedSql",
          header: "Scan Expression",
          tip: "Combined conditions on data fetched during this scan.",
          renderer: function ScanDetailsCell({ rowData }) {
            return (
              <SchemaTableScanDetails
                databaseId={databaseId}
                whereExpression={rowData.whereClause.join(" AND ")}
                joinExpression={rowData.joinClause.join(" AND ")}
                referencedQueries={rowData.referencedQueries}
              />
            );
          },
        },
        {
          field: "oldCost",
          header: "Cost",
          style: "number",
          renderer: ({ fieldData }) => formatNumber(fieldData, 2),
        },
        {
          field: "scansPerMin",
          header: "Est. Scans / Min",
          tip: "Cumulative frequency of this scan across all queries it appears in.",
          style: "number",
          renderer: ({ fieldData }) => formatNumber(fieldData, 4),
        },
        {
          field: "costImprovement",
          header: "Cost Improvement",
          headerClassName: "text-right",
          className: "text-right",
          renderer: function CostImprovementCell({ fieldData }) {
            return fieldData > 1 ? (
              <span className={styles.scanImprovement}>
                {formatApproximateNumber(fieldData)}×
              </span>
            ) : (
              "-"
            );
          },
        },
      ]}
    />
  );
};

export default IndexAdvisorRelevantScans;
