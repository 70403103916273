---
title: 'Step 4: Configure the Collector'
install_track_title: Installation Guide (Amazon RDS and Amazon Aurora)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import InstallChoice from '../../components/InstallChoice'
import styles from '../../style.module.scss'

import imgAwsEc2 from '../../images/aws-ec2.svg'
import imgAwsEcs from '../../images/aws-ecs.svg'
import imgDocker from '../../images/logo_docker.svg'

export const SelectCollectorPlatform = () => {
  return (
    <InstallChoice
      docsRoot={false}
      items={[
        {
          link: "04_configure_the_collector_ec2",
          img: imgAwsEc2,
          providerName: "Amazon EC2 Instance",
        },
        {
          link: "04_configure_the_collector_ecs",
          img: imgAwsEcs,
          providerName: "Amazon ECS",
        },
        {
          link: "04_configure_the_collector_docker",
          img: imgDocker,
          providerName: "Docker on EC2",
        },
      ]}
    />
  )
}

Choose where you have installed the collector:

<SelectCollectorPlatform />
