import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import CheckStatusBadge from "components/CheckStatusBadge";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelTitleSearch from "components/PanelTitleSearch";
import { useLoadServerIfNeeded } from "utils/useLoadServerIfNeeded";

import {
  CheckList as CheckListType,
  CheckListVariables,
  CheckList_getChecks,
} from "./types/CheckList";

import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";
import Grid from "components/Grid";
import { useFeature } from "components/OrganizationFeatures";

type CheckListProps = {
  organizationSlug?: string;
  serverId?: string;
  databaseId?: string;
};

export const CHECK_INFO = {
  "index_advisor/missing_index": {
    title: "Index Advisor: Missing Indexes",
    titleDisplay: (
      <>
        <strong>Index Advisor:</strong> Missing Indexes
      </>
    ),
  },
  "index_advisor/indexing_engine": {
    title: "Index Advisor: Missing Indexes",
    titleDisplay: (
      <>
        <strong>Index Advisor:</strong> Missing Indexes
      </>
    ),
  },
  "queries/slowness": {
    title: "Query Performance: New Slow Queries",
    titleDisplay: (
      <>
        <strong>Query Performance:</strong> New Slow Queries
      </>
    ),
  },
  "connections/active_query": {
    title: "Connections: Active Queries",
    titleDisplay: (
      <>
        <strong>Connections:</strong> Active Queries
      </>
    ),
  },
  "connections/idle_transaction": {
    title: "Connections: Idle Transactions",
    titleDisplay: (
      <>
        <strong>Connections:</strong> Idle Transactions
      </>
    ),
  },
  "connections/blocking_query": {
    title: "Connections: Blocking Queries",
    titleDisplay: (
      <>
        <strong>Connections:</strong> Blocking Queries
      </>
    ),
  },
  "system/storage_space": {
    title: "System: Out of Disk Space",
    titleDisplay: (
      <>
        <strong>System:</strong> Out of Disk Space
      </>
    ),
  },
  "schema/index_invalid": {
    title: "Schema Statistics: Invalid Indexes",
    titleDisplay: (
      <>
        <strong>Schema Statistics:</strong> Invalid Indexes
      </>
    ),
  },
  "schema/index_unused": {
    title: "Schema Statistics: Unused Indexes",
    titleDisplay: (
      <>
        <strong>Schema Statistics:</strong> Unused Indexes
      </>
    ),
  },
  "settings/fsync": {
    title: "Config Tuning: Disabled fsync",
    titleDisplay: (
      <>
        <strong>Config Tuning:</strong> Disabled fsync
      </>
    ),
  },
  "settings/shared_buffers": {
    title: "Config Tuning: Too small shared_buffers",
    titleDisplay: (
      <>
        <strong>Config Tuning:</strong> Too small shared_buffers
      </>
    ),
  },
  "settings/stats": {
    title: "Config Tuning: Disabled stats collection",
    titleDisplay: (
      <>
        <strong>Config Tuning:</strong> Disabled stats collection
      </>
    ),
  },
  "settings/work_mem": {
    title: "Config Tuning: Too small work_mem",
    titleDisplay: (
      <>
        <strong>Config Tuning:</strong> Too small work_mem
      </>
    ),
  },
  "settings/enable_features": {
    title: "Config Tuning: Disabled planner features",
    titleDisplay: (
      <>
        <strong>Config Tuning:</strong> Disabled planner features
      </>
    ),
  },
  "replication/high_lag": {
    title: "Replication: High Replication Lag",
    titleDisplay: (
      <>
        <strong>Replication:</strong> High Replication Lag
      </>
    ),
  },
  "replication/follower_missing": {
    title: "Replication: Missing HA Follower",
    titleDisplay: (
      <>
        <strong>Replication:</strong> Missing HA Follower
      </>
    ),
  },
  "vacuum/inefficient_index_phase": {
    title: "VACUUM: Performance - Inefficient Index Phase",
    titleDisplay: (
      <>
        <strong>VACUUM:</strong> Performance - Inefficient Index Phase
      </>
    ),
  },
  "vacuum/xmin_horizon": {
    title: "VACUUM: Bloat - VACUUM Blocked By Xmin Horizon",
    titleDisplay: (
      <>
        <strong>VACUUM:</strong> Bloat - VACUUM Blocked By Xmin Horizon
      </>
    ),
  },
  "vacuum/insufficient_vacuum_frequency": {
    title: "VACUUM: Bloat - Insufficient VACUUM Frequency",
    titleDisplay: (
      <>
        <strong>VACUUM:</strong> Bloat - Insufficient VACUUM Frequency
      </>
    ),
  },
  "vacuum/txid_wraparound": {
    title: "VACUUM: Freezing - Approaching Transaction ID Wraparound",
    titleDisplay: (
      <>
        <strong>VACUUM:</strong> Freezing - Approaching TXID Wraparound
      </>
    ),
  },
  "vacuum/mxid_wraparound": {
    title: "VACUUM: Freezing - Approaching Multixact ID Wraparound",
    titleDisplay: (
      <>
        <strong>VACUUM:</strong> Freezing - Approaching MXID Wraparound
      </>
    ),
  },
};

const ISSUE_SEVERITY_TO_ORDER = {
  critical: 3,
  warning: 2,
  info: 1,
  okay: 0,
};

type Check = CheckList_getChecks & {
  checkTitle: string;
  checkTitleDisplay: React.ReactNode;
  status: string;
  statusOrder: number;
};

const ServerAndDatabaseCheckList: React.FunctionComponent<CheckListProps> = ({
  organizationSlug,
  serverId: serverIdFromRoute,
  databaseId,
}) => {
  const { serverId, serverIdLoaded } = useLoadServerIfNeeded(
    serverIdFromRoute,
    databaseId,
  );
  const { loading, error, data } = useQuery<CheckListType, CheckListVariables>(
    QUERY,
    {
      variables: {
        organizationSlug,
        serverId,
        databaseId,
      },
    },
  );
  if (loading || error || !serverIdLoaded) {
    return <Loading error={!!error} />;
  }

  const checks: Check[] = data.getChecks.map((c) => ({
    checkTitle: CHECK_INFO[c.groupName + "/" + c.checkName]?.title,
    checkTitleDisplay:
      CHECK_INFO[c.groupName + "/" + c.checkName]?.titleDisplay,
    status: c.openIssueMaxSeverity || "okay",
    statusOrder: ISSUE_SEVERITY_TO_ORDER[c.openIssueMaxSeverity || "okay"],
    ...c,
  }));

  if (!databaseId) {
    return (
      <CheckList
        title="Check List"
        checks={checks}
        serverId={serverId}
        organizationSlug={organizationSlug}
      />
    );
  }

  const serverChecks = checks.filter((c) => !c.database);
  const databaseChecks = checks.filter((c) => !!c.database);

  return (
    <>
      <CheckList
        title="Server-wide checks"
        checks={serverChecks}
        serverId={serverId}
        organizationSlug={organizationSlug}
        key="serverChecks"
      />
      <CheckList
        title="Database-specific checks"
        checks={databaseChecks}
        serverId={serverId}
        organizationSlug={organizationSlug}
        key="databaseChecks"
      />
    </>
  );
};

const CheckList: React.FunctionComponent<{
  title: string;
  checks: Check[];
  serverId?: string;
  organizationSlug?: string;
}> = ({ title, checks, serverId, organizationSlug }) => {
  const hasIAv3Issues = useFeature("indexAdvisorV3Issues");
  const [searchTerm, setSearchTerm] = useState("");
  const { databaseCheck, serverCheck, organizationCheck } = useRoutes();
  const titleSearch = (
    <PanelTitleSearch
      value={searchTerm}
      onChange={(newTerm: string) => {
        setSearchTerm(newTerm);
      }}
    />
  );

  checks = checks
    .filter((c) => {
      return (
        searchTerm === "" ||
        c.checkTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        c.status?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .filter((c) => {
      const isIndexInsightCheck =
        c.groupName === "index_advisor" && c.checkName === "indexing_engine";
      return !isIndexInsightCheck || hasIAv3Issues;
    });

  return (
    <Panel title={title} secondaryTitle={titleSearch}>
      <Grid
        className="grid grid-cols-[100px_minmax(40%,1fr)_minmax(10%,180px)_minmax(10%,180px)_minmax(10%,180px)]"
        data={checks}
        striped
        defaultSortBy="statusOrder"
        columns={[
          {
            field: "statusOrder",
            defaultSortOrder: "desc",
            header: "Status",
            renderer: function CheckStatusCell({ rowData }) {
              return <CheckStatusBadge status={rowData.status} />;
            },
          },
          {
            field: "checkTitle",
            header: "Check",
            renderer: function CheckTitleCell({ rowData }) {
              const isMissingIndexCheck =
                rowData.groupName === "index_advisor" &&
                rowData.checkName === "missing_index";
              const blurb =
                isMissingIndexCheck && hasIAv3Issues ? <> (legacy)</> : null;
              return (
                <Link
                  to={
                    rowData.database?.id
                      ? databaseCheck(
                          rowData.database.id,
                          rowData.groupName,
                          rowData.checkName,
                        )
                      : serverId
                      ? serverCheck(
                          serverId,
                          rowData.groupName,
                          rowData.checkName,
                        )
                      : organizationCheck(
                          organizationSlug,
                          rowData.groupName,
                          rowData.checkName,
                        )
                  }
                >
                  {rowData.checkTitleDisplay}
                  {blurb}
                </Link>
              );
            },
          },
          { field: "triggeredIssueCount", header: "Triggered Issues" },
          { field: "acknowledgedIssueCount", header: "Acknowledged Issues" },
          {
            field: "resolvedIssueCount30d",
            header: "Resolved In Last 30 Days",
          },
        ]}
      />
    </Panel>
  );
};

export default ServerAndDatabaseCheckList;
