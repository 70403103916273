import React, { useState } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Panel from "components/Panel";
import Loading from "components/Loading";

import {
  VacuumHistory as VacuumHistoryType,
  VacuumHistoryVariables,
  VacuumHistory_getVacuumRuns_vacuumRuns,
} from "./types/VacuumHistory";
import { useDateRange } from "components/WithDateRange";
import { useRoutes } from "utils/routes";
import PanelTitleSearch from "components/PanelTitleSearch";
import QUERY from "./Query.graphql";
import { makeFilter } from "utils/filter";
import Grid, { GridColumn } from "components/Grid";

type VacuumRun = VacuumHistory_getVacuumRuns_vacuumRuns & {
  dbName: string;
  postgresRoleName?: string;
  schemaName?: string;
  tableName?: string;
  duration: number;
};

const VacuumHistory: React.FunctionComponent<{
  serverId: string;
  databaseId?: string;
}> = ({ serverId, databaseId }) => {
  const { serverRole, serverVacuum } = useRoutes();
  const [{ from, to }] = useDateRange();
  const [searchTerm, setSearchTerm] = useState("");
  const { data, loading, error } = useQuery<
    VacuumHistoryType,
    VacuumHistoryVariables
  >(QUERY, {
    variables: {
      serverId,
      databaseId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });
  if (loading || error) {
    return (
      <Panel title="History">
        <Loading error={!!error} />
      </Panel>
    );
  }

  const titleSearch = (
    <PanelTitleSearch
      value={searchTerm}
      onChange={(newTerm: string) => {
        setSearchTerm(newTerm);
      }}
    />
  );

  // We already tell the server to only send us finished vacuums, but ensure this here
  const vacuumRuns: VacuumRun[] = data.getVacuumRuns.vacuumRuns
    .filter((v) => !!v.vacuumEnd)
    .map((v) => ({
      dbName: v.databaseName,
      postgresRoleName: v.postgresRole?.name,
      schemaName: v.schemaTable?.schemaName ?? "unknown schema",
      tableName: v.schemaTable?.tableName ?? "unknown table",
      duration: v.vacuumEnd - v.vacuumStart,
      ...v,
    }));

  const gridData = vacuumRuns.filter(
    makeFilter(searchTerm, "postgresRoleName", "schemaName", "tableName"),
  );

  const columns: GridColumn<
    (typeof gridData)[number],
    keyof (typeof gridData)[number]
  >[] = [
    {
      field: "schemaName",
      header: "Schema",
    },
    {
      field: "tableName",
      header: "Table",
      renderer: function TableCell({ rowData, fieldData }) {
        return (
          <span>
            <Link to={serverVacuum(serverId, rowData.identity)}>
              {fieldData}
            </Link>
            {rowData.toast && " (TOAST)"}
          </span>
        );
      },
    },
    {
      field: "duration",
      header: "Duration",
      renderer: function DurationCell({ fieldData }) {
        return moment.duration(fieldData * 1000).humanize();
      },
      defaultSortOrder: "desc",
    },
    {
      field: "vacuumEnd",
      header: "Finished",
      renderer: function VacuumEndCell({ fieldData }) {
        return moment.unix(fieldData).format("ll LTS z");
      },
      defaultSortOrder: "desc",
    },
    {
      field: "postgresRoleName",
      header: "Role / Autovacuum",
      renderer: function RoleCell({ rowData, fieldData }) {
        if (fieldData && rowData.postgresRole) {
          return (
            <Link to={serverRole(serverId, rowData.postgresRole.id)}>
              {fieldData}
            </Link>
          );
        } else if (rowData.autovacuum) {
          return "autovacuum";
        } else {
          return "-";
        }
      },
    },
  ];

  if (!databaseId) {
    columns.unshift({
      field: "dbName",
      header: "Database",
    });
  }

  const gridClassname = databaseId
    ? "grid-cols-[100px,minmax(20%,1fr),min(15%,120px),min(20%,220px),min(10%,140px)]"
    : "grid-cols-[100px,100px,minmax(20%,1fr),min(15%,120px),min(20%,220px),min(10%,140px)]";

  return (
    <Panel title="History" secondaryTitle={titleSearch}>
      <Grid
        className={gridClassname}
        striped
        defaultSortBy="vacuumEnd"
        data={gridData}
        columns={columns}
      />
    </Panel>
  );
};

export default VacuumHistory;
