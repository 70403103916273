import React from "react";
import NodeTypeIcon from "../NodeTypeIcon";

import classNames from "classnames";

import { Node } from "types/explain";

import styles from "./style.module.scss";

const NodeHeading: React.FunctionComponent<{
  node: Node;
  className?: string;
  onNodeClick?: () => void;
}> = ({ node, className, onNodeClick, children }) => {
  const nodeType = node["Node Type"];
  const nodeTypeDetails = node["Scan Direction"] || node["Operation"];
  const nodeLabel = node["Parallel Aware"] ? "Parallel " + nodeType : nodeType;
  return (
    <div
      className={classNames(styles.heading, className)}
      data-nodeheading
      title={nodeType}
      onClick={onNodeClick}
      style={{ cursor: onNodeClick ? "pointer" : "default" }}
    >
      <span className={styles.nodeId}>{node.extra.id}</span>
      <span className={styles.title}>
        <NodeTypeIcon nodeType={nodeType} />
        &nbsp; {nodeLabel}
      </span>
      {nodeTypeDetails && <> ({nodeTypeDetails})</>}
      {children}
    </div>
  );
};

export default NodeHeading;
