import React, { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// See https://github.com/getsentry/sentry-javascript/issues/4118
import useRoutingInstrumentation from "react-router-v6-instrumentation";

import { useAppConfig } from "components/WithAppConfig";
import {
  useCurrentOrganization,
  useCurrentServer,
  useCurrentUser,
} from "components/WithCurrentOrganization";
import { useRouteTopLevelReference } from "utils/useRouteTopLevelReference";

const WithSentry: React.FunctionComponent = ({ children }) => {
  const { enterpriseEdition, sentryDsn, sentryTracesSampleRate } =
    useAppConfig();
  const routingInstrumentation = useRoutingInstrumentation();

  useEffect(() => {
    if (!enterpriseEdition && sentryDsn) {
      Sentry.init({
        dsn: sentryDsn,
        tracesSampleRate: sentryTracesSampleRate,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation,
            tracingOrigins: [/^\//, "/graphql"],
            shouldCreateSpanForRequest: (url) => {
              return (
                !url.match(/\/users\/auth\/?/) && !url.match(/\/users\/jwt\/?$/)
              );
            },
          }),
        ],
      });
    }
  }, [
    routingInstrumentation,
    enterpriseEdition,
    sentryDsn,
    sentryTracesSampleRate,
  ]);

  const user = useCurrentUser();
  useEffect(() => {
    Sentry.setUser({ id: user.id, email: user.email });
  }, [user]);

  const { databaseId } = useRouteTopLevelReference();
  const server = useCurrentServer(true);
  const organization = useCurrentOrganization(true);
  useEffect(() => {
    Sentry.setTags({
      database_id: databaseId,
      server_id: server?.id,
      organization_id: organization?.id,
      organization_name: organization?.name,
    });
  }, [databaseId, server, organization]);

  return <>{children}</>;
};

export default WithSentry;
