import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Plan, Node } from "types/explain";

type NodeSelection = {
  node: Node | undefined;
  explicit: boolean;
};

type PlanContext = {
  selectedNodeId: number | string;
  setSelectedNodeId: (newId: number | string) => void;
  selectedNode: NodeSelection;
};

const SelectedNodeContext = React.createContext<PlanContext>({
  selectedNodeId: 1,
  setSelectedNodeId: () => {},
  selectedNode: undefined,
});

const WithNodeSelection: React.FunctionComponent<{
  initialValue: number | string;
  plan: Plan;
}> = ({ children, initialValue, plan }) => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [selectedNodeId, setSelectedNodeId] = useState(initialValue);
  const hashNodeMatch = hash.length > 0 && hash.match(/^#node-(.+)$/);
  const match = hashNodeMatch && hashNodeMatch[1];
  const number = Number(match);
  const currHashNode = Number.isNaN(number) ? match : number;
  useEffect(() => {
    if (currHashNode && currHashNode != selectedNodeId) {
      setSelectedNodeId(currHashNode);
    }
  }, [currHashNode, selectedNodeId]);
  const hashAwareSetter = (nodeId: number | string): void => {
    navigate(`#node-${nodeId}`);
  };
  const currentValue = {
    selectedNodeId,
    setSelectedNodeId: hashAwareSetter,
    selectedNode: {
      node: findNode(plan, selectedNodeId),
      explicit: !!currHashNode,
    },
  };
  return (
    <SelectedNodeContext.Provider value={currentValue}>
      {children}
    </SelectedNodeContext.Provider>
  );
};

export function useSelectedNode(): [
  NodeSelection,
  (newId: number | string) => void,
] {
  const { selectedNode, setSelectedNodeId } = useContext(SelectedNodeContext);

  return [selectedNode, setSelectedNodeId];
}

const findNode: (plan: Plan, nodeId: number | string) => Node | undefined = (
  plan,
  nodeId,
) => {
  let result: Node;

  const doFindNode: (node: Node) => void = (node) => {
    if (node.extra.id === nodeId || node["Subplan Name"] == `CTE ${nodeId}`) {
      result = node;
      return;
    }
    if (node.Plans) {
      for (let i = 0; !result && i < node.Plans.length; i++) {
        doFindNode(node.Plans[i]);
      }
    }
  };

  for (let i = 0; !result && i < plan.length; i++) {
    doFindNode(plan[i].Plan);
  }
  return result;
};

export default WithNodeSelection;
