import React from "react";

import { Link } from "react-router-dom";
import moment from "moment";
import pluralize from "pluralize";

import {
  formatCost,
  formatDateMonthDay,
  formatDateMonthDayYear,
} from "utils/format";
import { Currency } from "utils/currency";

import PanelTileGroup, {
  PanelTile,
  TileLine,
  TileMainText,
} from "components/PanelTileGroup";
import { useRoutes } from "utils/routes";
import ContactSupportLink from "components/ContactSupportLink";
import { SubscriptionInterval } from "types/graphql-global-types";

type PlanInfo = {
  subscriptionPlanId: string;
  subscriptionPlanName: string;
  isTrial: boolean;
  expiredTrial: boolean;
  trialEndsAt: number | null;
  subscriptionCancelationPending: boolean;
  subscriptionCancelationEffectiveAt: number | null;
  canceledSubscription: boolean;
};

const PlanHighlights: React.FunctionComponent<{
  organizationSlug: string;
  planInfo: PlanInfo;
  serverCount: number;
  serverLimit: number;
  nextChargeAmount: number;
  nextChargeCurrency: Currency;
  nextChargeDueDate: number;
  subscriptionBilledManually: boolean;
  subscriptionInterval: SubscriptionInterval | null;
  subscriptionCardType: string | null;
  subscriptionCardLast4: string | null;
  subscriptionCardExpMonth: number | null;
  subscriptionCardExpYear: number | null;
}> = ({
  organizationSlug,
  planInfo,
  serverCount,
  serverLimit,
  nextChargeAmount,
  nextChargeCurrency,
  nextChargeDueDate,
  subscriptionBilledManually,
  subscriptionInterval,
  subscriptionCardType,
  subscriptionCardLast4,
  subscriptionCardExpMonth,
  subscriptionCardExpYear,
}) => {
  if (planInfo.isTrial || planInfo.expiredTrial) {
    return (
      <PlanHighlightsTrial
        organizationSlug={organizationSlug}
        serverUsage={serverCount}
        serverLimit={serverLimit}
        expired={planInfo.expiredTrial}
        trialEndsAt={
          planInfo.trialEndsAt != null
            ? moment.unix(planInfo.trialEndsAt)
            : null
        }
      />
    );
  } else if (
    subscriptionBilledManually ||
    planInfo.subscriptionPlanId === "enterprise_cloud"
  ) {
    return (
      <PlanHighlightsBilledManually
        serverUsage={serverCount}
        serverLimit={serverLimit}
        planName={planInfo.subscriptionPlanName}
      />
    );
  } else if (planInfo.canceledSubscription) {
    return (
      <PlanHighlightsCanceledSubscription
        organizationSlug={organizationSlug}
        serverUsage={serverCount}
        serverLimit={serverLimit}
      />
    );
  } else if (planInfo.subscriptionCancelationPending) {
    return (
      <PlanHighlightsCancelationPending
        organizationSlug={organizationSlug}
        serverUsage={serverCount}
        serverLimit={serverLimit}
        subscriptionInterval={subscriptionInterval}
        subscriptionCancelationEffectiveAt={moment.unix(
          planInfo.subscriptionCancelationEffectiveAt,
        )}
      />
    );
  } else {
    return (
      <PlanHighlightsStandard
        organizationSlug={organizationSlug}
        serverUsage={serverCount}
        serverLimit={serverLimit}
        nextChargeAmount={nextChargeAmount}
        nextChargeCurrency={nextChargeCurrency}
        nextChargeDueDate={moment.unix(nextChargeDueDate)}
        planId={planInfo.subscriptionPlanId}
        subscriptionInterval={subscriptionInterval}
        subscriptionCardType={subscriptionCardType}
        subscriptionCardLast4={subscriptionCardLast4}
        subscriptionCardExpMonth={subscriptionCardExpMonth}
        subscriptionCardExpYear={subscriptionCardExpYear}
      />
    );
  }
};

const PlanHighlightsBilledManually: React.FunctionComponent<{
  planName: string;
  serverUsage: number;
  serverLimit: number;
}> = ({ planName, serverUsage, serverLimit }) => {
  return (
    <PanelTileGroup>
      <PanelTile title="Plan">
        <TileMainText>{planName}</TileMainText>
      </PanelTile>
      <ServerLimitTile serverUsage={serverUsage} serverLimit={serverLimit}>
        <ContactSupportLink simpleStyle>
          Contact us to increase server limit
        </ContactSupportLink>
      </ServerLimitTile>
      <PanelTile title="Billing information">
        <TileLine>
          <ContactSupportLink simpleStyle>
            Contact us to update plan
          </ContactSupportLink>
        </TileLine>
      </PanelTile>
    </PanelTileGroup>
  );
};

const PlanHighlightsCancelationPending: React.FunctionComponent<{
  organizationSlug: string;
  serverUsage: number;
  serverLimit: number;
  subscriptionInterval: SubscriptionInterval | null;
  subscriptionCancelationEffectiveAt: moment.Moment;
}> = ({
  organizationSlug,
  serverUsage,
  serverLimit,
  subscriptionInterval,
  subscriptionCancelationEffectiveAt,
}) => {
  const {
    organizationSubscriptionUpdateBillingDetails,
    organizationSubscriptionUpdateCreditCard,
    organizationSubscriptionComparePlans,
    organizationSubscriptionInvoices,
  } = useRoutes();
  return (
    <PanelTileGroup>
      <PanelTile
        title={`Current ${
          subscriptionInterval == SubscriptionInterval.YEAR
            ? "yearly"
            : "monthly"
        } bill`}
      >
        <TileMainText>&mdash;</TileMainText>
      </PanelTile>
      <ServerLimitTile serverUsage={serverUsage} serverLimit={serverLimit} />
      <PanelTile title="Subscription ends">
        <TileMainText>
          {subscriptionInterval == SubscriptionInterval.YEAR
            ? formatDateMonthDayYear(subscriptionCancelationEffectiveAt)
            : formatDateMonthDay(subscriptionCancelationEffectiveAt)}
        </TileMainText>
      </PanelTile>
      <PanelTile title="Billing information">
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionUpdateBillingDetails(organizationSlug)}
          >
            Update billing details
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionUpdateCreditCard(organizationSlug)}
          >
            Update payment method
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionInvoices(organizationSlug)}
          >
            View invoices
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionComparePlans(organizationSlug)}
          >
            Restart subscription
          </Link>
        </TileLine>
      </PanelTile>
    </PanelTileGroup>
  );
};

const PlanHighlightsCanceledSubscription: React.FunctionComponent<{
  organizationSlug: string;
  serverUsage: number;
  serverLimit: number;
}> = ({ organizationSlug, serverUsage, serverLimit }) => {
  const {
    organizationSubscriptionInvoices,
    organizationSubscriptionUpdateBillingDetails,
    organizationSubscriptionUpdateCreditCard,
    organizationSubscriptionComparePlans,
  } = useRoutes();
  return (
    <PanelTileGroup>
      <PanelTile title="Subscription">
        <TileMainText>Canceled</TileMainText>
      </PanelTile>
      <ServerLimitTile serverUsage={serverUsage} serverLimit={serverLimit} />
      <PanelTile title="Next Payment Due">
        <TileMainText>&mdash;</TileMainText>
      </PanelTile>
      <PanelTile title="Billing information">
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionUpdateBillingDetails(organizationSlug)}
          >
            Update billing details
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionUpdateCreditCard(organizationSlug)}
          >
            Update payment method
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionInvoices(organizationSlug)}
          >
            View invoices
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionComparePlans(organizationSlug)}
          >
            Restart subscription
          </Link>
        </TileLine>
      </PanelTile>
    </PanelTileGroup>
  );
};

const PlanHighlightsTrial: React.FunctionComponent<{
  organizationSlug: string;
  serverUsage: number;
  serverLimit: number;
  expired: boolean;
  trialEndsAt: moment.Moment;
}> = ({ serverUsage, serverLimit, trialEndsAt, expired, organizationSlug }) => {
  const {
    organizationSubscriptionUpdateBillingDetails,
    organizationSubscriptionComparePlans,
  } = useRoutes();
  const daysRemaining =
    trialEndsAt == null
      ? 0
      : (trialEndsAt.valueOf() - Date.now()) / (24 * 60 * 60 * 1000);
  const timeRemainingBlurb = expired
    ? "Expired"
    : daysRemaining > 1
    ? pluralize("day", Math.floor(daysRemaining), true) + " left"
    : "Less than 24h left";
  return (
    <PanelTileGroup>
      <PanelTile title="Trial">
        <TileMainText>{timeRemainingBlurb}</TileMainText>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionComparePlans(organizationSlug)}
          >
            Upgrade
          </Link>
        </TileLine>
      </PanelTile>
      <ServerLimitTile serverUsage={serverUsage} serverLimit={serverLimit}>
        <Link to={organizationSubscriptionComparePlans(organizationSlug)}>
          Upgrade
        </Link>
      </ServerLimitTile>
      <PanelTile title="Next payment due">
        <TileMainText>&mdash;</TileMainText>
      </PanelTile>
      <PanelTile title="Billing information">
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionUpdateBillingDetails(organizationSlug)}
          >
            Update billing details
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionComparePlans(organizationSlug)}
          >
            Upgrade and add payment method
          </Link>
        </TileLine>
      </PanelTile>
    </PanelTileGroup>
  );
};

const PlanHighlightsStandard: React.FunctionComponent<{
  organizationSlug: string;
  serverUsage: number;
  serverLimit: number;
  nextChargeAmount: number;
  nextChargeCurrency: Currency;
  nextChargeDueDate: moment.Moment;
  planId: string;
  subscriptionCardType: string | null;
  subscriptionCardLast4: string | null;
  subscriptionCardExpMonth: number | null;
  subscriptionCardExpYear: number | null;
  subscriptionInterval: SubscriptionInterval | null;
}> = ({
  organizationSlug,
  serverUsage,
  serverLimit,
  nextChargeDueDate,
  nextChargeAmount,
  nextChargeCurrency,
  planId,
  subscriptionCardType,
  subscriptionCardLast4,
  subscriptionCardExpMonth,
  subscriptionCardExpYear,
  subscriptionInterval,
}) => {
  const {
    organizationSubscriptionInvoices,
    organizationSubscriptionUpdateBillingDetails,
    organizationSubscriptionUpdateCreditCard,
    organizationSubscriptionActivate,
    organizationSubscriptionCancel,
  } = useRoutes();
  const cardInfo =
    subscriptionCardType &&
    subscriptionCardLast4 &&
    subscriptionCardExpMonth &&
    subscriptionCardExpYear
      ? `${subscriptionCardType} ending in *${subscriptionCardLast4} (expires ${subscriptionCardExpMonth}/${String(
          subscriptionCardExpYear,
        ).slice(-2)})`
      : undefined;
  return (
    <PanelTileGroup>
      <PanelTile
        title={`Current ${
          subscriptionInterval == SubscriptionInterval.YEAR
            ? "yearly"
            : "monthly"
        } bill`}
      >
        <TileMainText>
          {formatCost(nextChargeAmount, nextChargeCurrency)}
        </TileMainText>
      </PanelTile>
      <ServerLimitTile serverUsage={serverUsage} serverLimit={serverLimit}>
        <Link to={organizationSubscriptionActivate(organizationSlug, planId)}>
          Increase server limit
        </Link>
      </ServerLimitTile>
      <PanelTile title="Next payment due">
        <TileMainText>
          {subscriptionInterval == SubscriptionInterval.YEAR
            ? formatDateMonthDayYear(nextChargeDueDate)
            : formatDateMonthDay(nextChargeDueDate)}
        </TileMainText>
        {cardInfo && <TileLine>{cardInfo}</TileLine>}
      </PanelTile>
      <PanelTile title="Billing information">
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionUpdateBillingDetails(organizationSlug)}
          >
            Update billing details
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionUpdateCreditCard(organizationSlug)}
          >
            Update payment method
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionInvoices(organizationSlug)}
          >
            View invoices
          </Link>
        </TileLine>
        <TileLine>
          <Link
            className="block"
            to={organizationSubscriptionCancel(organizationSlug)}
          >
            Cancel plan
          </Link>
        </TileLine>
      </PanelTile>
    </PanelTileGroup>
  );
};

const ServerLimitTile: React.FunctionComponent<{
  serverUsage: number;
  serverLimit: number;
}> = ({ serverUsage, serverLimit, children }) => {
  const hasLimit = serverLimit != null;
  return (
    <PanelTile title={`Billable server ${hasLimit ? "limit" : "usage"}`}>
      <TileMainText>
        <span
          className={
            hasLimit && serverUsage > serverLimit ? "text-[#C22426]" : undefined
          }
        >
          {serverUsage}
          {hasLimit && <> of {serverLimit}</>}
        </span>
      </TileMainText>
      {children && hasLimit && <TileLine>{children}</TileLine>}
    </PanelTile>
  );
};

export default PlanHighlights;
