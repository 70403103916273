import React from "react";

import styles from "./style.module.scss";
import { useHelpSetOpen, useHelpOverlaySupported } from "components/Help";

const ContactSupportLink: React.FunctionComponent<{
  simpleStyle?: boolean;
}> = ({ simpleStyle, children }) => {
  const overlaySupported = useHelpOverlaySupported();
  const openHelp = useHelpSetOpen();
  const handleContactSupportClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    openHelp(true);
  };
  const contactHref = overlaySupported ? "#" : "mailto:support@pganalyze.com";
  const contactOnClick = overlaySupported
    ? handleContactSupportClick
    : undefined;

  return (
    <a
      className={simpleStyle ? "" : styles.contactSupport}
      href={contactHref}
      onClick={contactOnClick}
    >
      {children}
    </a>
  );
};

export default ContactSupportLink;
