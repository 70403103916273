---
title: 'Step 3: Install the Collector'
install_track_title: Installation Guide (Amazon RDS and Amazon Aurora)
backlink_href: /docs/install
backlink_title: 'Installation Guide'
---

import InstallChoice from '../../components/InstallChoice'
import styles from '../../style.module.scss'

import imgAwsEc2 from '../../images/aws-ec2.svg'
import imgAwsEcs from '../../images/aws-ecs.svg'
import imgDocker from '../../images/logo_docker.svg'

export const SelectCollectorPlatform = () => {
  return (
    <InstallChoice
      docsRoot={false}
      items={[
        {
          link: "03_install_collector_ec2",
          img: imgAwsEc2,
          providerName: "Amazon EC2 Instance",
        },
        {
          link: "03_install_collector_ecs",
          img: imgAwsEcs,
          providerName: "Amazon ECS",
        },
        {
          link: "03_install_collector_docker",
          img: imgDocker,
          providerName: "Docker on EC2",
        },
      ]}
    />
  )
}

The [pganalyze collector](https://pganalyze.com/docs/collector) is a daemon process that continuously collects database statistics, and submits them to the pganalyze dashboard in recurring intervals.

We recommend installing the collector on an EC2 instance, but you can also run it through a Docker container:

<SelectCollectorPlatform />
