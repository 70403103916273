import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import mean from "lodash/mean";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-light-svg-icons";

import { useFeature } from "components/OrganizationFeatures";
import {
  formatNumber,
  formatPercent,
  formatDuration,
  formatMs,
  formatTimestampLong,
  formatBytes,
} from "utils/format";
import {
  AutovacuumEnabled,
  ConfigSettingDocsSnippet,
} from "components/DocsSnippet";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import PanelTable from "components/PanelTable";
import VacuumGraph from "components/VacuumGraph";

import {
  VacuumTableStats as VacuumTableStatsType,
  VacuumTableStats_getSchemaTableVacuumInfo,
  VacuumTableStats_getSchemaTableVacuumInfo_vacuumRuns as VacuumRunType,
  VacuumTableStats_getSchemaTableEvents as SchemaTableEvent,
  VacuumTableStats_getSchemaTableBloatInfo as BloatInfoType,
  VacuumTableStats_getSchemaTableStats,
} from "./types/VacuumTableStats";

import QUERY from "./Query.graphql";
import styles from "./style.module.scss";
import Loading from "components/Loading";
import { useDateRange } from "components/WithDateRange";
import { useRoutes } from "utils/routes";
import {
  faCheckCircle,
  faExclamationTriangle,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import DateRangeGraph from "components/Graph/DateRangeGraph";
import { AreaSeries, LineSeries } from "components/Graph/Series";
import { Datum } from "components/Graph/util";
import Grid, { NoDataGridContainer } from "components/Grid";
import { InfoIcon } from "components/Icons";

type VacuumRunWithProgressType = VacuumRunType & {
  freezingProgressDiff?: number;
  deadTuplesProgressDiff?: number;
};

const VacuumTableStats: React.FunctionComponent<{
  databaseId: string;
  tableId: string;
}> = ({ databaseId, tableId }) => {
  const [{ from, to }] = useDateRange();
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      databaseId,
      tableId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return <VacuumTableStatsDisplay data={data} from={from} to={to} />;
};

export const VacuumTableStatsDisplay: React.FunctionComponent<{
  data: VacuumTableStatsType;
  from: moment.Moment;
  to: moment.Moment;
}> = ({ data, from, to }) => {
  const events = data.getSchemaTableEvents;
  const serverId = data.getServerDetails.humanId;
  const vacuumEvents = events.filter(
    (evt: SchemaTableEvent): boolean =>
      evt.eventType == "auto_vacuum" || evt.eventType == "manual_vacuum",
  );
  const analyzeEvents = events.filter(
    (evt: SchemaTableEvent): boolean =>
      evt.eventType == "auto_analyze" || evt.eventType == "manual_analyze",
  );
  const fromTsTooEarly = from.isBefore(
    moment().subtract(7, "days").subtract(2, "minute"),
  );

  return (
    <>
      <VacuumPanel
        serverId={serverId}
        vacuumInfo={data.getSchemaTableVacuumInfo}
        vacuumStats={data.getSchemaTableStats}
        vacuumEvents={vacuumEvents}
        from={from}
        to={to}
        fromTsTooEarly={fromTsTooEarly}
      />
      <BloatPanel data={data.getSchemaTableBloatInfo} />
      <AnalyzePanel
        serverId={serverId}
        vacuumInfo={data.getSchemaTableVacuumInfo}
        analyzeEvents={analyzeEvents}
        fromTsTooEarly={fromTsTooEarly}
      />
    </>
  );
};

type ConfigValueProps = {
  currentValue: string | number | boolean;
  defaultValue: string | number | boolean;
  format: (a: string | number | boolean) => string;
};

const ConfigValue: React.FunctionComponent<ConfigValueProps> = ({
  currentValue,
  defaultValue,
  format,
}) => {
  if (currentValue != defaultValue) {
    return (
      <span
        className={styles.override}
        title={`Default setting: ${format(defaultValue)}`}
      >
        {format(currentValue)}
      </span>
    );
  } else {
    return <span>{format(currentValue)}</span>;
  }
};

const VacuumPanel: React.FunctionComponent<{
  serverId: string;
  vacuumInfo: VacuumTableStats_getSchemaTableVacuumInfo;
  vacuumStats: VacuumTableStats_getSchemaTableStats;
  vacuumEvents: SchemaTableEvent[];
  from: moment.Moment;
  to: moment.Moment;
  fromTsTooEarly: boolean;
}> = ({
  serverId,
  vacuumInfo,
  vacuumStats,
  vacuumEvents,
  from,
  to,
  fromTsTooEarly,
}) => {
  const hasActivitySnapshotsFeature = useFeature("activitySnapshots");
  const {
    autovacuumEnabled,
    autovacuumEnabledDefault,
    autovacuumVacuumThreshold,
    autovacuumVacuumThresholdDefault,
    autovacuumVacuumScaleFactor,
    autovacuumVacuumScaleFactorDefault,
    autovacuumFreezeMaxAge,
    autovacuumFreezeMaxAgeDefault,
    autovacuumMultixactFreezeMaxAge,
    autovacuumMultixactFreezeMaxAgeDefault,
    autovacuumVacuumInsertThreshold,
    autovacuumVacuumInsertThresholdDefault,
    autovacuumVacuumInsertScaleFactor,
    autovacuumVacuumInsertScaleFactorDefault,
    autovacuumVacuumCostDelay,
    autovacuumVacuumCostDelayDefault,
    autovacuumVacuumCostLimit,
    autovacuumVacuumCostLimitDefault,
  } = vacuumInfo;
  const avgVacuumDuration = mean(
    vacuumInfo.vacuumRuns
      .filter((v: VacuumRunType): boolean => !!v.vacuumEnd)
      .map((v: VacuumRunType): number =>
        v.vacuumEnd ? v.vacuumEnd - v.vacuumStart : 0,
      ),
  );

  const timeSelectedInDays = moment.duration(to.diff(from)).asDays();

  return (
    <Panel title="VACUUM Activity">
      <PanelTable horizontal borders equalWidth>
        <tbody>
          <tr>
            <th>Avg. VACUUM Duration</th>
            <td>
              {avgVacuumDuration ? formatDuration(avgVacuumDuration) : "n/a"}
            </td>
            <th>Number of VACUUMs / day</th>
            <td>
              {vacuumInfo.vacuumRuns.length > 0 && timeSelectedInDays >= 1
                ? formatNumber(
                    vacuumInfo.vacuumRuns.length / timeSelectedInDays,
                  )
                : "n/a"}
            </td>
          </tr>
          <tr>
            <th>
              Autovacuum Enabled <AutovacuumEnabled serverId={serverId} />
            </th>
            <td>
              <ConfigValue
                currentValue={autovacuumEnabled}
                defaultValue={autovacuumEnabledDefault}
                format={(val: boolean): string => (val ? "Yes" : "No")}
              />
            </td>
            <th></th>
            <td></td>
          </tr>
          <tr>
            <th>
              <ConfigSettingDocsSnippet
                configName={"autovacuum_vacuum_cost_delay"}
                serverId={serverId}
              />
            </th>
            <td>
              <ConfigValue
                currentValue={autovacuumVacuumCostDelay}
                defaultValue={autovacuumVacuumCostDelayDefault}
                format={(val: number): string => formatMs(val, 0, true)}
              />
            </td>
            <th>
              <ConfigSettingDocsSnippet
                configName={"autovacuum_vacuum_cost_limit"}
                serverId={serverId}
              />
            </th>
            <td>
              <ConfigValue
                currentValue={autovacuumVacuumCostLimit}
                defaultValue={autovacuumVacuumCostLimitDefault}
                format={formatNumber}
              />
            </td>
          </tr>
          <tr>
            <th>
              <ConfigSettingDocsSnippet
                configName={"autovacuum_vacuum_threshold"}
                serverId={serverId}
              />
            </th>
            <td>
              <ConfigValue
                currentValue={autovacuumVacuumThreshold}
                defaultValue={autovacuumVacuumThresholdDefault}
                format={formatNumber}
              />
            </td>
            <th>
              <ConfigSettingDocsSnippet
                configName={"autovacuum_vacuum_scale_factor"}
                serverId={serverId}
              />
            </th>
            <td>
              <ConfigValue
                currentValue={autovacuumVacuumScaleFactor}
                defaultValue={autovacuumVacuumScaleFactorDefault}
                format={(val: number): string => formatPercent(val, 0)}
              />
            </td>
          </tr>
          <tr>
            <th>
              <ConfigSettingDocsSnippet
                configName={"autovacuum_freeze_max_age"}
                serverId={serverId}
              />
            </th>
            <td>
              <ConfigValue
                currentValue={autovacuumFreezeMaxAge}
                defaultValue={autovacuumFreezeMaxAgeDefault}
                format={formatNumber}
              />
            </td>
            <th>
              <ConfigSettingDocsSnippet
                configName={"autovacuum_multixact_freeze_max_age"}
                serverId={serverId}
              />
            </th>
            <td>
              <ConfigValue
                currentValue={autovacuumMultixactFreezeMaxAge}
                defaultValue={autovacuumMultixactFreezeMaxAgeDefault}
                format={formatNumber}
              />
            </td>
          </tr>
          {autovacuumVacuumInsertScaleFactor && (
            <tr>
              <th>
                <ConfigSettingDocsSnippet
                  configName={"autovacuum_vacuum_insert_threshold"}
                  serverId={serverId}
                />
              </th>
              <td>
                <ConfigValue
                  currentValue={autovacuumVacuumInsertThreshold}
                  defaultValue={autovacuumVacuumInsertThresholdDefault}
                  format={(val: number): string =>
                    val === -1 ? "Off" : formatNumber(val)
                  }
                />
              </td>
              <th>
                <ConfigSettingDocsSnippet
                  configName={"autovacuum_vacuum_insert_scale_factor"}
                  serverId={serverId}
                />
              </th>
              <td>
                <ConfigValue
                  currentValue={autovacuumVacuumInsertScaleFactor}
                  defaultValue={autovacuumVacuumInsertScaleFactorDefault}
                  format={(val: number): string => formatPercent(val, 0)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </PanelTable>
      {hasActivitySnapshotsFeature ? (
        <VacuumStats
          serverId={serverId}
          vacuumInfo={vacuumInfo}
          tableStats={vacuumStats}
        />
      ) : (
        <>
          {vacuumEvents.length > 0 ? (
            <Grid
              className="grid-cols-[190px_230px_1fr] border-t"
              data={vacuumEvents}
              columns={[
                {
                  field: "id",
                  header: "Type",
                  renderer: function TypeCell() {
                    return <span className="label label-info">VACUUM</span>;
                  },
                },
                {
                  field: "eventType",
                  header: "Started By",
                  renderer: ({ fieldData }) =>
                    fieldData == "auto_vacuum" ? "autovacuum" : "User",
                },
                {
                  field: "occurredAt",
                  header: "Time Finished",
                  renderer: function TimeFinishedCell({ fieldData }) {
                    const timestamp = formatTimestampLong(
                      moment.unix(fieldData),
                    );
                    const timeAgo = moment.unix(fieldData).fromNow();
                    return `${timestamp} · ${timeAgo}`;
                  },
                },
              ]}
            />
          ) : (
            <NoDataGridContainer className="border-t">
              No autovacuum or manual VACUUM activity in selected time range.
            </NoDataGridContainer>
          )}
          {fromTsTooEarly && (
            <NoDataGridContainer className="bg-yellow-50 rounded-b-[5px]">
              <FontAwesomeIcon icon={faClock} className="mr-1 align-[-2px]" />
              VACUUM activity history is limited to 7 days, data available after{" "}
              {formatTimestampLong(moment().subtract(7, "days"))}.
            </NoDataGridContainer>
          )}
        </>
      )}
    </Panel>
  );
};

const VacuumStats: React.FunctionComponent<{
  serverId: string;
  vacuumInfo: VacuumTableStats_getSchemaTableVacuumInfo;
  tableStats: VacuumTableStats_getSchemaTableStats;
}> = ({ serverId, vacuumInfo, tableStats }) => {
  const [showToast, setShowToast] = useState(false);
  const { serverRole, serverVacuum } = useRoutes();
  const {
    autovacuumVacuumThreshold,
    autovacuumVacuumScaleFactor,
    autovacuumFreezeMaxAge,
    autovacuumMultixactFreezeMaxAge,
    autovacuumVacuumInsertThreshold,
    autovacuumVacuumInsertScaleFactor,
  } = vacuumInfo;
  const vacuumRuns = amendProgressData(vacuumInfo.vacuumRuns, showToast);

  // Add extra info about the collector version when there is no progress stats available
  // as it indicates that the collector version is old.
  const collectorVersionTip =
    " Progress info is only available on collector version 0.49.0 and up.";
  const freezingProgressCollectorVersionTip = vacuumRuns.every((vr) => {
    !vr.relfrozenxidStart;
  })
    ? collectorVersionTip
    : "";
  const deadTupleProgressCollectorVersionTip = vacuumRuns.every((vr) => {
    !vr.deadTuplesStart;
  })
    ? collectorVersionTip
    : "";

  return (
    <>
      <PanelSection>
        <VacuumGraph
          serverId={serverId}
          vacuumRuns={vacuumInfo.vacuumRuns}
          tableStats={tableStats}
          autovacuumVacuumThreshold={autovacuumVacuumThreshold}
          autovacuumVacuumScaleFactor={autovacuumVacuumScaleFactor}
          autovacuumFreezeMaxAge={autovacuumFreezeMaxAge}
          autovacuumMultixactFreezeMaxAge={autovacuumMultixactFreezeMaxAge}
          autovacuumVacuumInsertThreshold={autovacuumVacuumInsertThreshold}
          autovacuumVacuumInsertScaleFactor={autovacuumVacuumInsertScaleFactor}
          showToast={showToast}
        />
      </PanelSection>
      <PanelSection>
        <div className="flex justify-end items-center">
          <input
            type="checkbox"
            checked={showToast}
            id="show_toast"
            className="!mt-0"
            onChange={(evt) => setShowToast(evt.target.checked)}
          />
          <label className="text-[14px] font-normal pl-[4px] pr-[12px] mb-0">
            Show TOAST VACUUMs
          </label>
        </div>
      </PanelSection>
      {vacuumRuns.length > 0 ? (
        <Grid
          className="grid-cols-[1fr_100px_60px_180px_180px_180px_180px] border-t"
          data={vacuumRuns}
          columns={[
            {
              field: "id",
              header: "ID",
              renderer: function IDCell({ rowData }) {
                return rowData.identity != "0" ? (
                  <Link to={serverVacuum(serverId, rowData.identity)}>
                    {rowData.identity}
                  </Link>
                ) : (
                  "n/a"
                );
              },
            },
            {
              field: "autovacuum",
              header: "Started By",
              renderer: function StartByCell({ fieldData, rowData }) {
                return fieldData
                  ? "autovacuum"
                  : rowData.postgresRole && (
                      <Link to={serverRole(serverId, rowData.postgresRole.id)}>
                        {rowData.postgresRole.name}
                      </Link>
                    );
              },
            },
            {
              field: "toast",
              header: "TOAST",
              renderer: ({ fieldData }) => (fieldData ? "Yes" : "No"),
            },
            {
              field: "vacuumStart",
              header: "Time Started",
              renderer: ({ fieldData }) =>
                formatTimestampLong(moment.unix(fieldData)),
              defaultSortOrder: "desc",
            },
            {
              field: "vacuumEnd",
              header: "Time Finished",
              renderer: ({ fieldData }) =>
                fieldData
                  ? formatTimestampLong(moment.unix(fieldData))
                  : "currently running",
              defaultSortOrder: "desc",
            },
            {
              field: "freezingProgressDiff",
              header: "Freezing Progress",
              tip: `Freezing progress may not occur with a normal autovacuum when its focus is cleaning up dead tuples. Lack of freezing progress with anti-wraparound vacuums indicates a problem with a warning icon.${freezingProgressCollectorVersionTip}`,
              renderer: function FreezingProgressCell({ rowData }) {
                return (
                  <FreezingProgress
                    relfrozenxidStart={rowData.relfrozenxidStart}
                    relfrozenxidEnd={rowData.relfrozenxidEnd}
                    expectFreezing={rowData.expectFreezing}
                    vacuumRunning={!rowData.vacuumEnd}
                  />
                );
              },
            },
            {
              field: "deadTuplesProgressDiff",
              header: "Dead Tuple Progress",
              tip: `Dead tuples can increase if there are more dead tuples produced while vacuum is running. It is not necessarily a problem unless the pattern persists.${deadTupleProgressCollectorVersionTip}`,
              renderer: function DeadTupleProgressCell({ rowData }) {
                return (
                  <DeadTuplesProgress
                    deadTuplesStart={rowData.deadTuplesStart}
                    deadTuplesEnd={rowData.deadTuplesEnd}
                    vacuumRunning={!rowData.vacuumEnd}
                  />
                );
              },
            },
          ]}
        />
      ) : (
        <NoDataGridContainer className="border-t">
          No autovacuum or manual VACUUM activity in selected time range.
        </NoDataGridContainer>
      )}
    </>
  );
};

function amendProgressData(vacuumRuns: VacuumRunType[], showToast: boolean) {
  // vacuumRuns comes as the vacuumStart ascend order (oldest vacuumStart -> newest vacuumStart)
  // Go through the data and amend progress data (relfrozenxidStart and deadTuplesStart)
  // to distinct the cases that multiple vacuum runs happened within the one full snapshot time frame (10 mins).
  return vacuumRuns
    .filter((run) => {
      // Select only non-toast vacuum by default
      return showToast || !run.toast;
    })
    .map<VacuumRunWithProgressType>((run, i, runs) => {
      const currentRun: VacuumRunWithProgressType = { ...run };
      const nextRun = runs[i + 1];
      if (!nextRun) {
        // The last element, no need to amend
        return run;
      }

      if (!run.relfrozenxidStart || !run.deadTuplesStart) {
        // Progress data is not present, no need to amend
        return run;
      }
      if (nextRun.vacuumEnd - run.vacuumEnd > 10 * 60) {
        // Previous vacuum ran more than the full snapshot time ago (10 mins), no need to amend
        return run;
      }

      // When this vacuum and the next vacuum are within the same full snapshot time frame,
      // there will be no difference for progress as progress data is generated based on the full snapshot.
      // Flag runs "no data" by overwriting the start value with Infinity when this and next runs have no difference.
      if (
        run.relfrozenxidStart == nextRun.relfrozenxidStart &&
        run.relfrozenxidEnd == nextRun.relfrozenxidEnd
      ) {
        currentRun.relfrozenxidStart = Infinity;
      }
      if (
        run.deadTuplesStart == nextRun.deadTuplesStart &&
        run.deadTuplesEnd == nextRun.deadTuplesEnd
      ) {
        currentRun.deadTuplesStart = Infinity;
      }
      // These numbers are not completely accurate diffs, but close enough as an input of sort
      currentRun.freezingProgressDiff =
        (currentRun.relfrozenxidEnd ?? currentRun.relfrozenxidStart ?? 0) -
        (currentRun.relfrozenxidStart ?? 0);
      currentRun.deadTuplesProgressDiff =
        (currentRun.deadTuplesStart ?? 0) -
        (currentRun.deadTuplesEnd ?? currentRun.deadTuplesStart ?? 0);

      return currentRun;
    })
    .reverse();
}

const FreezingProgress: React.FunctionComponent<{
  relfrozenxidStart?: number;
  relfrozenxidEnd?: number;
  expectFreezing?: boolean;
  vacuumRunning: boolean;
}> = ({
  relfrozenxidStart,
  relfrozenxidEnd,
  expectFreezing,
  vacuumRunning,
}) => {
  // relfrozenxidStart null or 0 means that the collector is not sending out these data
  if (!relfrozenxidStart) {
    return <>n/a</>;
  }
  let progressIcon = <></>;
  let relfrozenxidDiffStr;

  // Special case that there are multiple vacuums happening at the same time and no meaningful progress data can be generated
  // Also show no data when the vacuum is running currently
  if (relfrozenxidStart === Infinity || vacuumRunning) {
    relfrozenxidDiffStr = <span className="text-[#777777]">no data</span>;
  } else {
    const relfrozenxidDiff =
      (relfrozenxidEnd ?? relfrozenxidStart) - relfrozenxidStart;

    if (relfrozenxidDiff === 0) {
      if (expectFreezing) {
        // vacuum expecting freezing but not making freezing progress
        progressIcon = (
          <FontAwesomeIcon icon={faExclamationTriangle} color="#777777" />
        );
        relfrozenxidDiffStr = (
          <span className="font-mono text-[#777777]">+0</span>
        );
      } else {
        relfrozenxidDiffStr = (
          <span className="font-mono text-[#777777]">+0</span>
        );
      }
    } else if (relfrozenxidDiff > 0) {
      progressIcon = <FontAwesomeIcon icon={faCheckCircle} color="#43962a" />;
      relfrozenxidDiffStr = (
        <span className="font-mono text-[#43962a]">
          +{formatNumber(relfrozenxidDiff)}
        </span>
      );
    } else {
      // This diff should really never be negative
      progressIcon = (
        <FontAwesomeIcon icon={faQuestionCircle} color="#777777" />
      );
      relfrozenxidDiffStr = (
        <span className="text-[#777777]">invalid data</span>
      );
    }
  }

  return (
    <span>
      {progressIcon} {relfrozenxidDiffStr}
    </span>
  );
};

const DeadTuplesProgress: React.FunctionComponent<{
  deadTuplesStart?: number;
  deadTuplesEnd?: number;
  vacuumRunning: boolean;
}> = ({ deadTuplesStart, deadTuplesEnd, vacuumRunning }) => {
  // deadTuplesStart null or 0 means that the collector is not sending out these data
  if (!deadTuplesStart) {
    return <>n/a</>;
  }
  let progressIcon = <></>;
  let deadTuplesDiffStr;

  // Special case that there are multiple vacuums happening at the same time and no meaningful progress data can be generated
  // Also show no data when the vacuum is running currently
  if (deadTuplesStart === Infinity || vacuumRunning) {
    deadTuplesDiffStr = <span className="text-[#777777]">no data</span>;
  } else {
    const deadTuplesDiff = deadTuplesStart - (deadTuplesEnd ?? deadTuplesStart);

    if (deadTuplesDiff > 0) {
      progressIcon = <FontAwesomeIcon icon={faCheckCircle} color="#43962a" />;
      deadTuplesDiffStr = (
        <span className="font-mono text-[#43962a]">
          -{formatNumber(deadTuplesDiff)}
        </span>
      );
    } else {
      // This diff can be negative when dead tuples increased more than vacuum removed
      deadTuplesDiffStr = (
        <span className="font-mono text-[#777777]">
          +{formatNumber(Math.abs(deadTuplesDiff))}
        </span>
      );
    }
  }

  return (
    <span>
      {progressIcon} {deadTuplesDiffStr}
    </span>
  );
};

const BloatPanel: React.FunctionComponent<{ data: BloatInfoType | null }> = ({
  data,
}) => {
  if (!data || data.stats.length === 0) {
    return null;
  }

  const bloatGraphData = {
    dataSize: data.stats.map((bloatVal) => {
      return [bloatVal.ts, bloatVal.estimatedDataBytes] as Datum;
    }),
    bloatSize: data.stats.map((bloatVal) => {
      return [bloatVal.ts, bloatVal.estimatedBloatBytes] as Datum;
    }),
  };

  const rowsGraphData = {
    rows: data.stats.map((bloatVal) => {
      return [bloatVal.ts, bloatVal.estimatedRows] as Datum;
    }),
  };

  const latestValues = data.stats[data.stats.length - 1];
  const { estimatedDataBytes, estimatedBloatBytes } = latestValues;
  const latestDataBytes = formatBytes(estimatedDataBytes, { ifNull: "n/a" });
  const latestBloatBytes = formatBytes(estimatedBloatBytes, { ifNull: "n/a" });

  return (
    <Panel title="Estimated Table Bloat">
      {!data.estimateAccountsForToast && (
        <PanelSection>
          <InfoIcon /> TOAST statistics are missing. Please&nbsp;
          <a
            rel="noopener"
            target="_blank"
            href="https://pganalyze.com/docs/collector/upgrading"
          >
            upgrade the collector
          </a>
          .
        </PanelSection>
      )}
      <PanelTable horizontal borders equalWidth>
        <tbody>
          <tr>
            <th>Estimated Data Size</th>
            <td>{latestDataBytes}</td>
            <th>Estimated Table Bloat</th>
            <td>{latestBloatBytes}</td>
          </tr>
        </tbody>
      </PanelTable>
      <PanelSection>
        <DateRangeGraph
          axes={{
            left: {
              format: formatBytes,
            },
          }}
          series={[
            { type: AreaSeries, key: "dataSize", label: "Est. Data Size" },
            { type: AreaSeries, key: "bloatSize", label: "Est. Bloat Size" },
          ]}
          data={bloatGraphData}
        />
        <DateRangeGraph
          axes={{
            left: {
              format: "count",
            },
          }}
          series={[{ type: LineSeries, key: "rows", label: "Est. Rows" }]}
          data={rowsGraphData}
        />
      </PanelSection>
    </Panel>
  );
};

const AnalyzePanel: React.FunctionComponent<{
  serverId: string;
  vacuumInfo: VacuumTableStats_getSchemaTableVacuumInfo;
  analyzeEvents: SchemaTableEvent[];
  fromTsTooEarly: boolean;
}> = ({ serverId, vacuumInfo, analyzeEvents, fromTsTooEarly }) => {
  const {
    autovacuumAnalyzeThreshold,
    autovacuumAnalyzeThresholdDefault,
    autovacuumAnalyzeScaleFactor,
    autovacuumAnalyzeScaleFactorDefault,
  } = vacuumInfo;

  return (
    <Panel title="ANALYZE Activity">
      <PanelTable horizontal borders equalWidth>
        <tbody>
          <tr>
            <th>
              <ConfigSettingDocsSnippet
                configName={"autovacuum_analyze_threshold"}
                serverId={serverId}
              />
            </th>
            <td>
              <ConfigValue
                currentValue={autovacuumAnalyzeThreshold}
                defaultValue={autovacuumAnalyzeThresholdDefault}
                format={formatNumber}
              />
            </td>
            <th>
              <ConfigSettingDocsSnippet
                configName={"autovacuum_analyze_scale_factor"}
                serverId={serverId}
              />
            </th>
            <td>
              <ConfigValue
                currentValue={autovacuumAnalyzeScaleFactor}
                defaultValue={autovacuumAnalyzeScaleFactorDefault}
                format={(val: number): string => formatPercent(val, 0)}
              />
            </td>
          </tr>
        </tbody>
      </PanelTable>
      {analyzeEvents.length > 0 ? (
        <Grid
          className="grid-cols-[190px_230px_1fr] border-t"
          data={analyzeEvents}
          columns={[
            {
              field: "id",
              header: "Type",
              renderer: function TypeCell() {
                return <span className="label label-success">ANALYZE</span>;
              },
            },
            {
              field: "eventType",
              header: "Started By",
              renderer: ({ fieldData }) =>
                fieldData == "auto_analyze" ? "autovacuum" : "User",
            },
            {
              field: "occurredAt",
              header: "Time Finished",
              renderer: function TimeFinishedCell({ fieldData }) {
                const timestamp = formatTimestampLong(moment.unix(fieldData));
                const timeAgo = moment.unix(fieldData).fromNow();
                return `${timestamp} · ${timeAgo}`;
              },
            },
          ]}
        />
      ) : (
        <NoDataGridContainer className="border-t">
          No autovacuum or manual ANALYZE activity in selected time range.
        </NoDataGridContainer>
      )}
      {fromTsTooEarly && (
        <NoDataGridContainer className="bg-yellow-50 rounded-b-[5px]">
          <FontAwesomeIcon icon={faClock} className="mr-1 align-[-2px]" />
          ANALYZE activity history is limited to 7 days, data available after{" "}
          {formatTimestampLong(moment().subtract(7, "days"))}.
        </NoDataGridContainer>
      )}
    </Panel>
  );
};

export default VacuumTableStats;
