import React, { useContext } from "react";

import { OrganizationFeatures as OrganizationFeaturesType } from "./types/OrganizationFeatures";

import { useCurrentOrganization } from "components/WithCurrentOrganization";

export type Feature = Exclude<keyof OrganizationFeaturesType, "__typename">;
export const FeatureContext = React.createContext({});

export const OrganizationFeatures: React.FunctionComponent = ({ children }) => {
  const currentOrganization = useCurrentOrganization(true);
  if (!currentOrganization) {
    return <>{children}</>;
  }

  const { enabledFeatures } = currentOrganization;

  return (
    <FeatureContext.Provider value={enabledFeatures}>
      {children}
    </FeatureContext.Provider>
  );
};

export const useFeature: (feature: Feature | undefined) => boolean = (
  feature,
) => {
  const features = useContext(FeatureContext);
  if (!features) {
    throw new Error(
      "must have ancestor OrganizationFeatures component and current organization",
    );
  }
  if (!feature) {
    return true;
  }
  return features[feature];
};
